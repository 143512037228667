import PrescriptionType from "./prescriptionType.enum"

const ImageMap = Object.freeze({
  // Authors
  '63d4f9dc34dd101bc5e37e5e': '/img/adora-square.jpg',
  '63eebdb42935caebe4209ae8': '/img/steven-square.jpg',
  '63d4f9dc34dd101bc5e37e61': '/img/alex-square.jpg',
  '63d4f9dd8653989cbdb57016': '/img/layla-square.jpg',
  '652af9326bab3576641248f5': '/img/layla-square.jpg',
  '63d4f9dd8653989cbdb56ff8': '/img/shakima-square.jpg',

  // Products
  '6601b39a3c645f10ecf2ceca': '/img/cholesterol-treatment-test.png',
  '65f998b23c645f10ec63ce6b': '/img/panels/heart_health_panel.jpg',

  // Prescriptions
  [PrescriptionType.EZETIMIBE_10MG]: `/img/prescriptions/ezetimibe_10mg.jpg`,
  [PrescriptionType.ROSUVASTATIN_2500MCG]: `/img/prescriptions/rosuvastatin_5mg.jpg`,
  [PrescriptionType.ROSUVASTATIN_5MG]: `/img/prescriptions/rosuvastatin_5mg.jpg`,
  [PrescriptionType.ROSUVASTATIN_10MG]: `/img/prescriptions/rosuvastatin_10mg.jpg`,
  [PrescriptionType.ROSUVASTATIN_20MG]: `/img/prescriptions/rosuvastatin_20mg.jpg`,
  [PrescriptionType.EVOLOCUMAB_140MG]: `/img/prescriptions/evolocumab_140mg.jpg`,
  [PrescriptionType.LEQVIO_284MG]: `/img/prescriptions/leqvio_284mg.jpg`,
  [PrescriptionType.METFORMIN_500MG]: `/img/prescriptions/metformin_500mg.jpg`,
  [PrescriptionType.ALLOPURINOL_100MG]: `/img/prescriptions/allopurinol_100mg.jpg`,
  [PrescriptionType.METOPROLOL_TARTRATE_50MG_CCTA]: `/img/prescriptions/metoprolol_50mg.jpg`,
  [PrescriptionType.ICOSAPENT_ETHYL_2G]: `/img/prescriptions/icosapent_ethyl_2g.jpg`,
})

export default ImageMap
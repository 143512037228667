import { Row, Col, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import "./membershipPoints.scss";

const { Paragraph } = Typography

export const MembershipPoints = () => {
  const points = [
    {
      title: `Your Own Longevity Physician`,
      description: `Access personalized care with convenient, online consultations with a longevity physician.`
    }, 
    {
      title: `Referrals for Additional Specialized Care`,
      description: `Get referrals to top specialists for additional care outside of Instalab's scope like colonoscopies and skin cancer screenings.`
    },
    {
      title: `Personal Care Concierge`,
      description: `Enjoy a dedicated assistant to manage appointments and coordinate your healthcare.`
    },
    {
      title: `Exclusive Access & Discounts`,
      description: "Gain early access to any new, advanced diagnostics and special pricing on popular tests."
    }, 
    {
      title: `Flexible Membership`,
      description: `Pause or cancel anytime, no strings attached.`
    },
  ];

  return (
    <div className="membership-points">
      <div className="point-list">
        <Row gutter={10}>
          {points.map((point, index) => (
            <div className="point-item">
              <Row>
                <Col className="point-col">
                  <CheckCircleOutlined className="point-icon" />
                </Col>
                <Col className="text-col">
                  <Paragraph className="point-title">
                    {point.title}
                  </Paragraph>
                  <Paragraph className="point-description">
                    {point.description}
                  </Paragraph>
                </Col>
              </Row>
            </div>
          ))}
        </Row>
      </div>
    </div>
  )
}
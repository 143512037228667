import { Typography, Row, Col }  from 'antd'
import './memberConfirmed.scss'
import { CloseOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useEffect, useState } from "react";
import Breakpoint from "../../../../enums/breakpoint.enum";
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import { useNavigate } from 'react-router-dom';
import PhotoHelper from '../../../../helpers/photo.helper';
import { listProductTypes } from '../../../../services/productType.service';
import useWidth from '../../../../hooks/useWidth.hook';
import FlowType from '../../../../enums/flowType.enum';

const { Paragraph } = Typography

const productTypeCodes = [
  ProductTypeCode.LONGEVITY_PANEL,
  ProductTypeCode.GALLERI,
  ProductTypeCode.CTCALCIUM,
  ProductTypeCode.WATCHPAT_ONE
]

const MemberConfirmed = ({ footer }) => {
  const width = useWidth()
  const navigate = useNavigate()
  const [productTypes, setProductTypes] = useState()
  
  useEffect(() => {
    fetchProductTypes()
  }, [])

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: productTypeCodes
        }
      }
    }))
  }

  const Panels = ({ productTypes }) => {
    return (
      <div className="panels">
        <Row gutter={12}>
          {productTypes?.sort((a, b) => {
            return productTypeCodes.findIndex(code => code === a.code) - productTypeCodes.findIndex(code => code === b.code)
          }).map(({ code, path, flowType, title, tagline, cost, photos }) => (
            <Col 
              key={`panel-${code}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div 
                className="panel"
                onClick={() => navigate(path ? path : `/flow/${flowType}`)}
              >
                <div className="panel-img-container">
                  {PhotoHelper.format({
                    jpg: photos[0].jpg,
                    webp: photos[0].webp,
                    className:"panel-img"
                  })}

                  <ArrowRightOutlined className="panel-icon" />
                </div>

                <Paragraph className="panel-title">
                  {title}
                </Paragraph>
                <Paragraph className="panel-description">
                  {tagline}
                </Paragraph>
                {/* <Text className="panel-discount-price">
                  ${ProductHelper.getMemberPrice(cost)}
                </Text> */}
                {/* <Text className="panel-original-price">
                  ${cost}
                </Text> */}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  return (
    <div 
      className="member-confirmed"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <div 
        className="member-confirmed-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Welcome to Instalab! 
        </Paragraph>
        <Paragraph className="member-description">
          We're thrilled to have you as a member. You can reach out anytime to <a style={{color: `gray`}} href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any questions or requests.
          
          <br/>
          <CloseOutlined className="close-icon" />

          <h4>Getting Started</h4>
          If you haven’t done comprehensive blood work in the past year, let’s get that taken care of first. This step ensures we have the most up-to-date information on your health:
          
          {footer({ 
            buttonText: "Get Longevity Panel",
            buttonCheck: false,
            buttonStyle: {
              height: 50,
              fontSize: 18,
              width: 300,
              margin: '40px auto 0 auto',
              display: 'block',
            },
            enterStyle: {
              display: 'none',
            },
            onCustomSubmit: () => navigate(`/longevity-panel`)
          })} 
          <br/><CloseOutlined className="close-icon" />

          <h4>Book Your First Consult</h4>
          Ready to take the next step? Schedule your session with one of our longevity physicians today:

          {footer({ 
            buttonText: "Schedule Consult",
            buttonCheck: false,
            buttonStyle: {
              height: 50,
              fontSize: 18,
              width: 300,
              margin: '40px auto 0 auto',
              display: 'block',
            },
            enterStyle: {
              display: 'none',
            },
            onCustomSubmit: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
          })} 
        </Paragraph>


{/* 
        {footer({ 
          buttonText: "Take Quiz",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: 300,
            margin: '40px auto 0 auto',
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          },
          onCustomSubmit: () => navigate('/flow/quiz')
        })} */}

        <div className="tests-header">
          <div className="tests-header-border"></div>
          <Paragraph className="tests-header-text">
            Most Popular Tests
          </Paragraph>
          <div className="tests-header-border"></div>
        </div>

        <Panels productTypes={productTypes} />
      </div>
    </div>
  )
}

export default MemberConfirmed
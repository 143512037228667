import React, { useEffect, useState } from 'react';
import { Modal, Checkbox, message } from 'antd';
import { updateAppointment } from '../../services/appointment.service'; // Assuming these services are available
import { listProducts } from '../../services/product.service';

export const UpdateProductsModal = ({ visible, appointment, onClose, onSuccess }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  
  // Fetch user's products when modal is opened
  useEffect(() => {
    if (appointment && visible) {
      fetchUserProducts(appointment.patients[0]._id);
    }
  }, [appointment, visible]);

  const fetchUserProducts = async (patientId) => {
    try {
      const products = await listProducts({
        filter: { patient: patientId}, 
        populate: [{path: 'productType'}]});
      setAvailableProducts(products);
      setSelectedProducts(appointment.products.map(p => p._id)); // Preselect existing products
    } catch (error) {
      message.error('Failed to fetch products.');
    }
  };

  const onProductSelectChange = (selectedProductIds) => {
    setSelectedProducts(selectedProductIds);
  };

  const onSaveProducts = async () => {
    try {
      await updateAppointment(appointment._id, { fields: {products: selectedProducts}});
      message.success('Products updated successfully');
      onSuccess(); // Notify parent component about success
      onClose();   // Close the modal
    } catch (error) {
      message.error('Failed to update products.');
    }
  };

  return (
    <Modal
      title="Update Products"
      visible={visible}
      onOk={onSaveProducts}
      onCancel={onClose}
    >
            <Checkbox.Group
            options={availableProducts.map(product => ({
                label: `${product.productType?.title} (Created: ${new Date(product.createdAt).toLocaleDateString()})`,
                value: product._id
            }))}
            value={selectedProducts}
            onChange={onProductSelectChange}
            />
    </Modal>
  );
};
import { Typography, Row, Col, message } from "antd"
import { useNavigate } from "react-router-dom"
import { EmailForm } from "../emailForm/emailForm.component"
import { subscribeBlog } from "../../services/blog.service"
import { Overview } from "../overview/overview.component";
import PhotoHelper from "../../helpers/photo.helper";
import './subscribeNewsletter.scss';

const { Paragraph } = Typography

export const SubscribeNewsletter = () => {
  const navigate = useNavigate()

  const Blogs = ({ items }) => {
    return (
      <div className="blog-list">
        <Row gutter={48}>
          {items.map(({ jpg, webp, title, description, path }, index) => (
            <Col 
              key={`blog-${index}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <div 
                className="blog"
                onClick={() => navigate(path, )}
              >
                {PhotoHelper.format({
                  jpg,
                  webp,
                  className:"blog-img"
                })}

                <Paragraph 
                  className="blog-header"
                  ellipsis={{
                    rows: 1
                  }}
                >
                  {title}
                </Paragraph>

                <Paragraph 
                  className="blog-description"
                  ellipsis={{
                    rows: 3
                  }}
                >
                  {description}
                </Paragraph>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  return (
    <div className="subscribe-newsletter">
      <Overview
        title="Peak Health Newsletter"
        description="Get the latest research, new products, and special offers right to your inbox."
      />

      <EmailForm 
        buttonText="Subscribe Now" 
        onSuccess={async (email) => {
          try {
            await subscribeBlog({ email })
            message.success("You're subscribed!")
          } catch (err) {
            message.error("Failed to subscribe.")
          }
        }}
      />

      <Blogs
        items={[
          {
            jpg: "/img/blogs/cac-scanner.png",
            // webp: "/img/cac.webp",
            title: "How a CAC Test Could Save Your Life",
            description: "The CAC test is a powerful, non-invasive tool for assessing your risk of heart disease. It measures the plaque buildup your arteries, an early indicator of atherosclerosis–a condition that leads to heart disease.",
            path: "/newsletter/cac",
          },
          {
          jpg: "/img/blogs/founder.jpg",
          webp: "/img/blogs/founder.webp",
          title: "Founder Health: Silent Sacrifice in the Pursuit of Success",
          description: `Starting a startup often means pushing health to the back burner. In the race to success, it's easy to think "I'll deal with my health when my startup succeeds." But “success” turns out to be a moving goal post. From raising the next big round, to making that pivotal hire, to achieving a growth milestone, to navigating a board issue. It’s never ending. Your health is perpetually sidelined.`,
          path: "/newsletter/founder",
        }, 

        // {
        //   jpg: "/img/blogs/sauna.jpg",
        //   webp: "/img/blogs/sauna.webp",
        //   title: "Dry Saunas: Longevity Drug or Internet Fad?",
        //   description: "Sure, your favorite podcaster loves saunas. Peter Attia, Andrew Huberman, Rhonda Patrick, take your pick. But if you trace their advice back to the original research papers, it all stems from two Finnish scientists: Tanjaniina Laukkanen (researcher) and Jari Laukkanen (professor, cardiologist).",
        //   path: "/newsletter/sauna",
        // },
         {
          jpg: "/img/blogs/cholesterol.jpg",
          webp: "/img/blogs/cholesterol.webp",
          title: "A More Aggressive Approach to Lowering Cholesterol",
          description: "Traditional medicine oversimplifies risk. LDL cholesterol (LDL-C) refers to the cholesterol carried in the bloodstream by particles called LDL. It is a key biomarker used for predicting the likelihood of developing atherosclerosis, the underlying disease that causes heart attack, stroke, and other cardiovascular events.",
          path: "/newsletter/why-be-aggressive-about-lowering-cholesterol",
        }]}
      />
    </div>
  )
}
import "./results.scss";
import { ResultsTable } from '../resultsTable/resultsTable.component';
import { useState, useEffect, useContext } from "react";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { useSearchParams, useParams } from "react-router-dom";
import { Button, message, Typography, Menu, Tooltip, Dropdown, Spin } from 'antd';
import { DownloadOutlined, LoadingOutlined, SendOutlined, UnorderedListOutlined, TableOutlined, FilterOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/user.context';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { addReportForPatient } from '../../services/patient.service';
import { SendFileModal } from '../sendFileModal/sendFileModal.component';
import { SingleResultsTable } from "../singleResultsTable/singleResultsTable.component";
import AbsoluteCategoryCode from "../../enums/absoluteCategoryCode.enum.js";
import TestCode from "../../enums/testCode.enum.js";
import Role from "../../enums/role.enum.js";
import { listAnnotatedTests } from "../../services/test.service";
import { listAnnotatedResults } from "../../services/result.service";
import { getPatient } from "../../services/patient.service.js";
import { getMe } from "../../services/user.service.js";
import { listReports } from "../../services/report.service.js";
import { listGoals } from "../../services/goal.service.js";
import { listAbsoluteCategories } from "../../services/absoluteCategory.service.js";


const { Text } = Typography;

const BLACKLIST = [
  TestCode.WEIGHT,
  TestCode.ANION_GAP,
  TestCode.TESTOSTERONE_TOTAL,
  TestCode.MPV,
  TestCode.SD_LDL,
  TestCode.TG_HDL,
  TestCode.BUN_CREATINE,
  TestCode.GRIP,
  TestCode.DEXA_TOTAL_LEAN_MASS,
  TestCode.DEXA_LEAN_MASS_ARMS,
  TestCode.DEXA_LEAN_MASS_LEGS,
  TestCode.ARACHIDONIC,
  TestCode.EPA,
  TestCode.DHA,
  TestCode.PSA_FREE,
  TestCode.FREE_TESTOSTERONE_DIRECT
];

const TESTORDER = [
  {
    absoluteCategory: AbsoluteCategoryCode.NUTRIENTS,
    order: [
      TestCode.VITAMIN_D,
      TestCode.VITAMIN_B1,
      TestCode.VITAMIN_B2,
      TestCode.VITAMIN_B6,
      TestCode.FOLATE,
      TestCode.VITAMIN_B12,
      TestCode.FERRITIN,
      TestCode.IRON_TOTAL,
      TestCode.TIBC,
      TestCode.IRON_SATURATION,
      TestCode.ZINC,
      TestCode.OMEGA_3_INDEX,
      TestCode.OMEGA_6_3,
      TestCode.ARACHIDONIC_EPA,
      TestCode.TRANSFAT_INDEX
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.KIDNEY,
    order:[
      TestCode.EGFR,
      TestCode.CYSTATIN_C,
      TestCode.CREATININE,
      TestCode.BUN,
      TestCode.UACR,
      TestCode.URINE_CREATININE,
      TestCode.URINE_ALBUMIN
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.THYROID,
    order: [
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.FREET3,
      TestCode.TPO_ANTIBODY,
      TestCode.THYROGLOBULIN_ANTIBODY
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.BLOOD_CELLS,
    order: [
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.PLATELET,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPOPROTEINS,
    order: [
      TestCode.APO_B
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPIDS,
    order: [
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.LDL_CHOLESTEROL_CALC,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.INFLAMMATION,
    order: [
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.LP_PLA2
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.HORMONES,
    order: [
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIVER,
    order: [
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.GGT,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.INDIRECT_BILIRUBIN,
      TestCode.GLOBULIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.ALBUMIN_GLOBULIN
    ]
  },
  {
  absoluteCategory: AbsoluteCategoryCode.IMMUNOLOGIC,
    order: [
      TestCode.ANA_SCREEN,
      TestCode.ANA_TITER,
      TestCode.ANA_PATTERN,
      TestCode.COMPLEMENT_TOTAL,
      TestCode.COMPLEMENT_C3,
      TestCode.COMPLEMENT_C4
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.METABOLISM,
    order: [
      TestCode.NMR_LP_IR_SCORE,
      TestCode.HOMAIR,
      TestCode.TGI,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.DEXA_VAT_MASS
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.VITAL_SIGNS,
    order: [
      TestCode.BMI,
      TestCode.BP_S,
      TestCode.BP_D,
      TestCode.GRIP_RIGHT,
      TestCode.GRIP_LEFT,
    ]
  }
];

// Lookup to manage the test ordering
const TESTORDER_LOOKUP = TESTORDER.reduce((acc, item) => {
  acc[item.absoluteCategory] = item.order.reduce((orderMap, code, index) => {
    orderMap[code] = index;
    return orderMap;
  }, {});
  return acc;
}, {});

export const Results = () => {
  const [filterValue, setFilterValue] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDownloading, setIsDownloading] = useState();
  const [isSendModalVisible, setIsSendModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isTableView, setIsTableView] = useState(false); // Default to list view
  const { currentUser } = useContext(UserContext);
  const [goal, setGoal] = useState()
  const [user, setUser] = useState()
  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [reports, setReports] = useState()
  const [absoluteCategories, setAbsoluteCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const { patientId } = useParams()

  useEffect(() => {
    fetchAbsoluteCategories();
  }, []);

  useEffect(() => {
    const filter = searchParams.get('filter') || 'all';
    setFilterValue(filter);
  }, [searchParams]);

  useEffect(() => {
    fetchUser()
  }, [patientId, currentUser])

  useEffect(() => {
    fetchPatientData()
  }, [user])

  const fetchAbsoluteCategories = async () => {
    setAbsoluteCategories(await listAbsoluteCategories())
  }

  const fetchUser = async () => {
    if (!currentUser) return

    let params = {
      select: 'location isAthlete height',
      populate: []
    }

    if (currentUser.role === Role.PATIENT) {
      setUser(await getMe(params))
    }  else if (patientId) {
      setUser(await getPatient(patientId, params))
    }
  }


  const fetchPatientData = async () => {
    if (!user) return;

    setIsLoading(true)
    setTests(await listAnnotatedTests({ patient: user._id }))
    setResults(await listAnnotatedResults({ patient: user._id }))
    const goals = await listGoals({ patient: user._id })
    if (goals?.length) {
      setGoal(goals[0])
    }
    setReports(await listReports({
      filter: {
        patient: user._id,
      },
      select: 'status healthScore healthPercentile',
      populate: [{
        path: 'result',
        select: 'collectedAt',
        populate: {
          path: 'order',
          select: 'panel'
        }
      }],
      sort: '-createdAt'
    }))

    setIsLoading(false)
  }



  const handleFilterChange = (value) => {
    setFilterValue(value);
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSearchParams({ ...params, filter: value });
  };

  // Download report for the patient
  const onDownload = async (_id) => {
    setIsDownloading(_id);
    const location = await addReportForPatient(_id);
    const filename = location.split('?')[0];

    axios
      .get(location, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename);
        message.success('PDF downloaded');
        setIsDownloading(null);
      });
  };

  const handleSendFileClick = (file) => {
    setSelectedFile(file);
    setIsSendModalVisible(true); // Open the send modal
  };

  // Toggle between table and list view
  const toggleView = () => {
    setIsTableView(!isTableView);
  };

  // Determine the title based on the view and filter
  const getTitle = () => {
    let title = isTableView ? (filterValue === 'all' ? 'All Results' : 'Filtered Results') : 'Latest Results';

    return title;
  };

  const filterOptions = [
    { key: 'all', label: 'Show All' },
    { key: 'high-risk', label: 'At Risk' },
    { key: 'high-moderate-risk', label: 'At Risk + Borderline' },
    { key: 'low-risk', label: 'Optimal' },
  ];
  

  const menu = (
    <Menu
      onClick={(e) => handleFilterChange(e.key)}
      items={filterOptions.map(option => ({
        key: option.key,
        label: option.label,
      }))}
    />
  );
  
  return currentUser && (
    <div className="results">
      <SendFileModal 
        selectedFile={selectedFile}
        isSendModalVisible={isSendModalVisible}
        setIsSendModalVisible={setIsSendModalVisible}
      />
      
      <PageHeader 
        title={getTitle()}  // Dynamic title based on view and filter
        actions={[
          <div className="actions-container" key="actions">


          <Tooltip title={isTableView ? "View latest values" : "View all results"}>
            <Button 
              icon={isTableView ? <UnorderedListOutlined /> : <TableOutlined />} 
              onClick={toggleView} 
              type='default' 
              className="toggle-view-btn"
            >
              {isTableView ? '' : ''}
            </Button>
            </Tooltip>

            {/* Dropdown for filtering results */}
            <Tooltip title={"Filter Results"}>
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="filter-btn">
                  <FilterOutlined />
                </Button>
              </Dropdown>
            </Tooltip>

            <Tooltip title="Fax your test results">
              <Button 
                type='default' 
                className="send-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSendFileClick({ name: "Instalab Lab Results", id: `${currentUser.email}`, folderName: currentUser?._id });
                }}
              >
                <SendOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Download your test results">
              <Button   
                type='default' 
                className="download-btn"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (isDownloading !== currentUser?._id) {
                    await onDownload(currentUser?._id);
                  }
                }}
              >
                {isDownloading === currentUser?._id ? (
                  <Text className="downloading-text">
                    <LoadingOutlined />
                  </Text>
                ) : <>
                  <DownloadOutlined />
                </>}
              </Button>
            </Tooltip>
          </div>
        ]}
      />

      {/* Conditionally render either SingleResultsTable or ResultsTable */}
      {isLoading ? (
        <div className="loading">
          <div className="loading-text">
            <Spin /> &nbsp;&nbsp;Loading
          </div>
        </div>
      ) : isTableView ? (
        <ResultsTable 
          blacklist={BLACKLIST}
          testorderLookup = {TESTORDER_LOOKUP}
          filterValue={filterValue}
          tests={tests}
          results={results}
          reports={reports}
          patientId={patientId}
          goal={goal}
          user={user}
          absoluteCategories={absoluteCategories}
         />
      ) : (
        <SingleResultsTable 
          blacklist={BLACKLIST}
          testorderLookup = {TESTORDER_LOOKUP}
          filterValue={filterValue}
          tests={tests}
          results={results}
          reports={reports}
          patientId={patientId}
          absoluteCategories={absoluteCategories}
        />
      )}
    </div>
  );
};
import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, Modal, message } from 'antd'
import { listConsults, removeConsult } from "../../services/consult.service"
import { PageHeader } from '../pageHeader/pageHeader.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import "./adminConsultsTable.scss";
import { Step } from '../proConsultFlow/proConsultFlow.component.js'
import { CalendarOutlined, DeleteOutlined, VideoCameraOutlined } from '@ant-design/icons'
import UrlHelper from '../../helpers/url.helper.js';
import FlowType from '../../enums/flowType.enum.js';
import { useNavigate, useParams } from 'react-router-dom';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { addProxyFlow } from '../../services/flow.service.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';
import Role from '../../enums/role.enum.js';
import AppointmentStatus from '../../enums/appointmentStatus.enum.js';
import classNames from 'classnames';

const { Text, Paragraph } = Typography

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const AdminConsultsTable = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const [consults, setConsults] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser, setCounts } = useContext(UserContext)
  const [filteredCount, setFilteredCount] = useState();
  const [consultTypes, setConsultTypes] = useState([])

  useEffect(() => {
    document.title = 'Instalab | Consultations'
  }, [])

  useEffect(() => {
    fetchConsults()
  }, [hasPatient, patientId])

  const fetchConsults = async () => {
    setIsLoading(true)
    if (hasPatient && !patientId) return

    let params = {
      select: '_id name start status remoteLocation reason questions',
      filter: {},
      populate: [{
        path: 'provider',
        select: 'firstName lastName npi'
      }, {
        path: 'flow',
        select: '_id products',
        populate: {
          path: 'products',
          select: 'productType',
          populate: {
            path: 'productType',
            select: 'code',
          }
        }
      }],
      sort: '-start'
    }

    if (hasPatient) {
      params.filter = {
        patient: patientId
      }
    } else {
      params.select = `${params.select} patient`
      params.populate = [
        ...params.populate,
        {
          path: 'patient',
          select: '_id firstName lastName'
        }
      ]
    }

    const fetchedConsults = await listConsults(params)
    setConsultTypes([...new Set(fetchedConsults.map(({ name }) => name))].sort())
    setConsults(fetchedConsults)
    setFilteredCount(fetchedConsults.length)
    setIsLoading(false)
  }

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }

  const getActionItems = (consult) => {
    let menuItems = []

    const isFuture = new Date(consult.start) > new Date()
    
    if (consult.flow && isFuture) {
      const flowStep = {
        [ProductTypeCode.CONSULT_LONGEVITY]: Step.CONSULT,
        [ProductTypeCode.CONSULT_HEART_HEALTH]: Step.CONSULT,
      }

      if (consult.remoteLocation) {
        menuItems.push( {
          key: 'meeting',
          label: (
            <Button onClick={() => {
              window.open(consult.remoteLocation, '_blank');
            }}>
              <VideoCameraOutlined /> Go To Meeting
            </Button>
          )
  
        })
      }

      menuItems.push({  
        key: 'reschedule',
        label: (
          <Button onClick={() => {
            navigate(`/pro-flow/${FlowType.PRO_CONSULT}/${flowStep[consult.flow.products[0].productType.code]}/${consult.flow._id}`)
          }}>
            <CalendarOutlined /> Reschedule
          </Button>
        )
      });
    }

    if (currentUser?.role === Role.ADMIN) {
      menuItems.push(
        {
          type:'divider'
        },
        {
        key: 'delete',
        label: (
          <Button 
            onClick={() => onRemove(consult._id)}
            className="remove-item"
          >
            <DeleteOutlined /> Remove
          </Button>
        ),
      })
    }
  
    return menuItems;
  }
  
  const getCustomFilter = (consult, value) => {
    return {
      patient: () => consult.patient && `${consult.patient.firstName} ${consult.patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      provider: () => consult.provider && `${consult.provider.firstName} ${consult.provider.lastName}`.toLowerCase().includes(value.toLowerCase())
    }
  }

  const onRemove = (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this consult?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk() {
        removeConsult(_id)
          .then(() => {
            setConsults(consults.filter(consult => consult._id !== _id));
            message.info('Consult removed');
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                consults: cachedCounts.consults - 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount-1)
          })
          .catch(err => {
            message.error('Failed to remove consult');
          });
      }
    });
  }

  // ReasonWithToggle Component
  const ReasonWithToggle = ({ reason }) => {
    return (
      <Paragraph
        ellipsis={{
          rows: 3, // Number of lines to show before truncation
          expandable: true, // Enable the "See More" option
          symbol: 'See More', // Text for "See More"
        }}
      >
        {reason}
      </Paragraph>
    );
  };
    

  return (
    <div className="admin-consults-table">
      <PageHeader
        title='Consultations'
        count={filteredCount}
        actions={
          <Button
            type='primary'
            onClick={async () => {
              let params = {
                type: FlowType.PRO_CONSULT
              }
              if (hasPatient) {
                params.user = patientId
              }
              const response = await addProxyFlow(params)
              navigate(`/pro-flow/${FlowType.PRO_CONSULT}/${Object.values(Step)[0]}/${response._id}`)
            }}
          >
            + Add New Consult
          </Button>
        }
      />

      <FlexibleTable
        isLoading={isLoading}
        records={consults}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[
        //   {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   width: 100,
        //   render: status => <Text className={classNames(`${status}-status`, "consult-status")}>{status}</Text>,
        //   filterDropdownType: FilterDropdownType.CHECKBOX,
        //   filterOptions: Object.values(AppointmentStatus)
        // }, 
        !hasPatient && {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            if (patient) {
              return (
                <a 
                  onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Consults'))}
                >
                  {patient.firstName} {patient.lastName}
                </a>
              )
            }
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        {
          title: 'Type',
          dataIndex: 'name',
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: consultTypes,
          render: name => name.replace('Consultation', '')
        }, 
        {
          title: 'Provider',
          dataIndex: 'provider',
          render: provider =>  <>{provider.npi && <>Dr. </>}{provider.firstName} {provider.lastName}</>,
          filterDropdownType: FilterDropdownType.INPUT
        }, 
        {
          title: 'Reason',
          dataIndex: 'questions',
          render: questions => questions && questions.length>0 &&  <ReasonWithToggle reason={questions[0].answer} />,
          width: 300
        },
        {
          title: 'Start',
          dataIndex: 'start',
          render: (start, { provider }) => start && convertTimeZone(start, provider).format('MMM D, YYYY')
        }, {
          dataIndex: 'startTime',
          render: (_, { start, provider }) => start && convertTimeZone(start, provider).format('h:mma z')
        },
      ]}
      />
    </div>
  )
}
const HeartEventType = Object.freeze({
  NOTE: 'Note',
  KIT_ORDERED: 'Kit Ordered',
  KIT_TRACKING: 'Kit Shipment Tracking',
  APPOINTMENT_SCHEDULED: 'Blood Draw Scheduled',
  APPOINTMENT_COMPLETE: 'Blood Draw Complete',
  CONSULT_SCHEDULED: 'Consult Scheduled',
  CONSULT_COMPLETE: 'Consult Complete',
  PRESCRIPTION_CREATED: 'Prescription Ordered',
  PRESCRIPTION_SENT: 'Prescription Sent to GoGoMeds',
  PRESCRIPTION_PAUSED: 'Prescription Paused',
  PRESCRIPTION_TRACKING: 'Prescription Shipment Tracking',
  RESULT_RETURNED: 'Test Results Returned',
  REMINDER: 'Reminder',
});

export default HeartEventType;

import React, { useEffect, useRef, useState  } from "react";
import { Button, Tag, Modal } from "antd";
import ImageGallery from "react-image-gallery";
import "./productHero.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate } from "react-router-dom";
import { ProductQuestions } from "../productQuestions/productQuestions.component";
import ProductHelper from "../../../helpers/product.helper";
import { RightOutlined} from "@ant-design/icons"

export const ProductHero = ({product, isMobileOrTablet}) => {

    const navigate = useNavigate()
    const ctaRef = useRef(null);  // Ref for the CTA button
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const scrollPosition = window.scrollY;
        // Show the button after scrolling 400px
        if (scrollPosition > 400) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to open modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Function to close modal
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onGo = () => {
        if (product.cta.url) {
            navigate(product.cta.url)
        }
        if (product.cta.onClick) {
            product.cta.onClick()
        }
    }

  
    return (   
        <div className="product-detail-hero-container">
                      
            <div className="product-photos-desktop">
                {product.photos.length === 1 ? <img className="product-photos-solo" src={product.photos[0].original}/> :
                <ImageGallery 
                    items={product.photos}
                    showThumbnails={!isMobileOrTablet}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={isMobileOrTablet}
                    thumbnailClass="product-photos-thumbnail"
                    showNav={false}
                    slideOnThumbnailOver={true}
                />}
            </div>
            <div className="product-description">
                <h1 className="product-title">{product.title}</h1>
                <p className="product-oneliner">{product.oneliner}</p>

                {product.originalPrice && (
                    product.hasKlarna ?
                    <div className="product-pricing">
                        ${product.originalPrice}
                        <span className="bnpl-option">
                            <a href="#" className="bnpl-link" onClick={showModal}>or as low as ${ProductHelper.getMonthlyPayment(product.originalPrice, 12, 0.0799).toFixed(0)} / month</a>
                        </span>
                    </div>
              
                    : <div className="product-pricing">
                        <span className="product-price">${product.originalPrice}{" "}</span>
                        {product.memberPrice && product.originalPrice > product.memberPrice && 
                            <>
                        <span>{"  "}or </span>
                        <span className="product-member-price">${product.memberPrice}</span>
                        <Tag className="member-tag">with Membership</Tag></>}
                    </div>)}

 
                <div className="product-photos-mobile">
                    <ImageGallery 
                        items={product.photos}
                        showThumbnails={!isMobileOrTablet}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={isMobileOrTablet}
                        thumbnailClass="product-photos-thumbnail"
                        showNav={false}
                    />
                </div>

                { product.note && <p className="product-note">{product.note}</p> }

                <div ref={ctaRef} className={`product-cta`}>
                    <Button 
                        className="product-cta-button" 
                        size="large" 
                        type="primary" 
                        onClick={onGo}
                    >
                        {product.cta.text}
                    </Button>
                </div>

                {/* <div className="product-points">
                    {product.points.map(point => <div className="product-point">{point}</div>)}
                </div> */}


                {product.faqs && 
                <ul className="product-top-faq">
                    <ProductQuestions questions={product.faqs.slice(0,4)}/>
                </ul>}
            </div>    

            {/* Modal for Financing Explanation */}
            <Modal
                title="Financing with Klarna"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Got It!
                    </Button>,
                ]}
            >
                <p>You can finance this purchase with Klarna for as low as ${ProductHelper.getMonthlyPayment(product.originalPrice, 12, 0.0799).toFixed(0)} / month over 12 months. Klarna offers simple and flexible payment plans with 7.99-29.99% APR. Your interest rate is based on your credit history.</p>
                <p>No hidden fees. Pay over time and split the cost into monthly payments.</p>
                <p>To find out your rate, hit the {product.cta.text} button.</p>
            </Modal> 

            {/* Scroll Button */}
            {showScrollButton && (
            <Button
                type="primary"
                className="product-cta-button fixed-cta"
                onClick={onGo}
            >
                {product.cta.text} <RightOutlined className="btn-icon" />
            </Button>)}

        </div>
        
    )
}
import { Form, message, Row, Col, Button, Checkbox, Typography } from "antd"
import "./card.scss"
import { CheckOutlined, CreditCardFilled, DeleteOutlined } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useContext } from "react"
import Color from '../../../../colors.scss'
import { CardElement } from '@stripe/react-stripe-js';
import { ReactComponent as AmexIcon } from '../../../../assets/svg/amex.svg'
import { ReactComponent as DinersClubIcon } from '../../../../assets/svg/diners_club.svg'
import { ReactComponent as DiscoverIcon } from '../../../../assets/svg/discover.svg'
import { ReactComponent as JCBIcon } from '../../../../assets/svg/jcb.svg'
import { ReactComponent as MastercardIcon } from '../../../../assets/svg/mastercard.svg'
import { ReactComponent as VisaIcon } from '../../../../assets/svg/visa.svg'
import { ReactComponent as UnionPayIcon } from '../../../../assets/svg/unionpay.svg'
import { ReactComponent as CartesBancairesIcon } from '../../../../assets/svg/cartes_bancaires.svg'
import { removeCard } from '../../../../services/card.service';
import { UserContext } from "../../../../contexts/user.context"
import Breakpoint from "../../../../enums/breakpoint.enum"
import { updateFlow } from "../../../../services/flow.service"
import { MembershipPointsModal } from "../../../membershipPointsModal/membersipPointsModal.component"
import classNames from "classnames"
import { PricingTableModal } from '../../../pricingTableModal/pricingTableModal.component'
import UserHelper from "../../../../helpers/user.helper"
import useWidth from "../../../../hooks/useWidth.hook"

const { Item } = Form
const { Text, Paragraph } = Typography

const cardLogos = {
  american_express: <AmexIcon />,
  amex: <AmexIcon />,
  diners_club: <DinersClubIcon />,
  discover: <DiscoverIcon />,
  jcb: <JCBIcon />,
  mastercard: <MastercardIcon />,
  visa: <VisaIcon />,
  unionpay: <UnionPayIcon />,
  cartes_bancaires: <CartesBancairesIcon  />,
}

export const Card = ({
  step,
  flow,
  setFlow,
  onSubmit,
  form,
  isSubmitting,
  onNextStep,
  cards,
  setCards,
}) => {
  const { currentUser, instalabMembership } = useContext(UserContext)
  const width = useWidth()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [openPricing, setOpenPricing] = useState()
  const hasCredit = UserHelper.hasCredit(currentUser, step?.productType)
  const [hasPurchased, setHasPurchased] = useState()
  const [activeCard, setActiveCard] = useState()

  useEffect(() => {
    setHasPurchased(getHasPurchased())
  }, [step, flow])

  useEffect(() => {
    fetchActiveCard()
  }, [cards])

  const fetchActiveCard = () => {
    if (cards?.length) {
      setActiveCard(cards[0])
    } else {
      setActiveCard(null)
    }
  }

  const getHasPurchased = () => {
    if (!step || !flow) return false
    if (step.addSubscription) {
      return flow.membership?.membershipType?.code === step.subscriptionType
    }
    if (step.addProduct) {
      return flow.products?.some(product => step.productTypes.includes(product.productType?.code))
    }
    return false
  }

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onContinue()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onSubmit]);

  const onContinue = async () => {
    (hasPurchased) ? await onNextStep() : await onSubmit()
    await fetchActiveCard()
  }

  const onRemoveCard = async (cardId) => {
    try {
      await removeCard(cardId, { flow: flow?._id })
      setActiveCard(null)
      if (cards?.length) {
        setCards(cards.filter(({ _id }) => _id !== cardId))
      }
      message.info('Card removed')
    } catch (err) {
      message.error('Failed to remove card')
    }
  }

  return (
    <div className="card">

      <PricingTableModal 
        open={openPricing}
        setOpen={setOpenPricing}
      />
      <Form
        form={form}
        className="pay-form"
        layout="vertical"
      >
        {step?.benefits?.length > 0 && (
          <div className="benefit-list">
            {step.benefits.map((benefit, benefitIndex) => (
              <div 
                key={`benefit-${benefitIndex}`}
                className="benefit-item"
              >
                <Row>
                  <Col className="benefit-col-1">
                    <CheckOutlined />
                  </Col>
                  <Col className="benefit-col-2">
                    <Text className="benefit-item-text">
                      {benefit}
                    </Text>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

        {step?.points?.length > 0 && (
          <div className="points">
            {step.points.map((point, pointIndex) => (
              <div 
                key={`point-${pointIndex}`}
                className="point-item"
              >
                <span className="point-icon">
                  {point.icon} 
                </span>
                <Text className="point-description">
                  {point.description}
                </Text>
              </div>
            ))}
          </div>
        )}

        {step?.instructions?.length > 0 && (
          <div className="instruction-list">
            {step.instructions.map(({ sections }, instructionIndex) => (
              <div 
                key={`instruction-${instructionIndex}`} 
                className="instruction-item"
              >
                <Row>
                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 80 : '100%',
                      marginBottom: width > Breakpoint.SM ? 0 : 10
                    }}
                  >
                    <Text className="instruction-index">
                      Step {instructionIndex+1}
                    </Text>
                  </Col>

                  <Col
                    style={{
                      width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                    }}
                  >
                    {sections.map(({ title, description }, sectionIndex) => (
                      <div 
                        key={`section-${instructionIndex}-${sectionIndex}`} 
                        className="section-item"
                      >
                        <Text className="section-title">
                          {title}
                        </Text>
                        <Paragraph className="section-description">
                          {description}
                        </Paragraph>
                      </div>
                    ))}
                    </Col>
                </Row>
              </div>
            ))}
          </div>
        )}

        {step.addLongevityMembership && !instalabMembership && !hasCredit && !hasPurchased && (
          <div className="add-membership">
            <Checkbox
              onChange={async e => {
                const newValue = e.target.checked
                setFlow({...flow, addLongevityMembership: newValue })
                try {
                  await updateFlow(flow._id, { addLongevityMembership: newValue })
                } catch (err) {
                  setFlow({...flow, addLongevityMembership: !newValue })
                }
              }}
              className={classNames(
                "add-membership-checkbox",
                flow?.addLongevityMembership ? "active-checkbox" : "default-checkbox"
              )}
              checked={flow?.addLongevityMembership}
            >
              <Paragraph className="add-membership-title">
                Instalab membership
              </Paragraph>
              <Paragraph className="add-membership-description">
                Includes your own health concierge who helps you reach your health goals and save up to 50% on all tests, consults and treatments. <u>Cancel anytime</u>.
                {/* Your own health concierge to help guide you and save up to 50% on all Instalab services. <u>You can easily cancel anytime.</u> */}
              </Paragraph>
            </Checkbox>

            <a
              onClick={e => {
                e.stopPropagation()
                setOpenMembershipPointsModal(true)
              }}
              className="view-membership-details"
            >
              View Details
            </a>

            <MembershipPointsModal 
              open={openMembershipPointsModal}
              setOpen={setOpenMembershipPointsModal}
            />
          </div>
        )}

        {!hasPurchased && !hasCredit && (
          <div className="pay-fields">
            {activeCard ? (
              <div className="saved-card-details">
                <span className="saved-card-logo">
                  {cardLogos[activeCard.brand?.toLowerCase().replace(/ /g, '_')]}
                </span>
    
                <div className="saved-card-content">
                  <Text className="saved-card-label">
                    Confirming payment will bill
                  </Text>
                  <Text className="saved-card-description">
                    {activeCard.brand} ending in {activeCard.last4}
                  </Text>
                </div>
    
                <Button
                  className="remove-btn"
                  icon={<DeleteOutlined />}
                  onClick={() => onRemoveCard(activeCard._id)}
                />
              </div>
            ) : ( 
              <Item
                label='Enter Payment Details'
                className="pay-item"
              >
                <CardElement 
                  options={{
                    disableLink: true,
                    style: {
                      base: {
                        iconColor: Color.primary_text,
                        color: Color.primary_text,
                        fontFamily: 'Lato, sans-serif',
                        margin: 30,
                        fontSize: '18px',
                        fontWeight: '400',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                          color: Color.secondary_text,
                        },
                        '::placeholder': {
                          color: Color.secondary_text,
                        },
                      },
                      invalid: {
                        iconColor: Color.error,
                        color: Color.error,
                      },
                    }
                  }}
                />
              </Item>
            )}
          </div>
        )}

        {(hasPurchased || hasCredit) && (
          <div className="paid-status">
            <CreditCardFilled /> Your payment is confirmed.
          </div>
        )}

        <FlowStepFooter 
          onSubmit={() => onContinue()} 
          buttonText={(hasPurchased || hasCredit) ? 'Continue' : 'Save & Continue'}
          isSubmitting={isSubmitting}
          enterStyle={{ display: 'none' }}
        />
      </Form>
    </div>
  )
}
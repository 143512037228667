import React, { useContext, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { UserContext } from "../../contexts/user.context.js";
import 'react-quill/dist/quill.snow.css';
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import Color from '../../colors.scss';
import parse from 'html-react-parser';
import { Typography, Tooltip as AntTooltip, Dropdown, Modal, Drawer, Spin, message, Button, Form, Select } from 'antd'
import { EditOutlined, DeleteOutlined, SyncOutlined, BorderOutlined, CheckCircleOutlined, MailOutlined, CalendarOutlined, FlagOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusOutlined } from '@ant-design/icons'
import './adminHeartHealth.scss';
import dayjs from 'dayjs'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';

import { refillPrescription, updatePrescriptionStatus } from '../../services/prescription.service.js';
import { getData } from '../../services/heart.service.js';
import classNames from 'classnames';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';
import { listTests } from '../../services/test.service.js';
import TestCode from '../../enums/testCode.enum.js';
import RiskLevel from '../../enums/riskLevel.enum.js';
import { addNote } from '../../services/note.service.js';
import { NoteForm } from '../noteForm/noteForm.component.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { addProduct, updateProduct } from '../../services/product.service.js';
import ProductStatus from '../../enums/productStatus.enum.js';
import { addProxyFlow } from '../../services/flow.service.js';
import FlowType from '../../enums/flowType.enum.js';
import { addCalendlyConsult } from '../../services/consult.service.js';
import { ReminderForm } from '../reminderForm/reminderForm.component.js';
import ReminderStatus from '../../enums/reminderStatus.enum.js';
import { removeReminder, updateReminder } from '../../services/reminder.service.js';
import PrescriptionType from '../../enums/prescriptionType.enum.js';
import { HeartTrendLine } from '../heartTrendLine/heartTrendLine.component.js';
import MembershipHelper from '../../helpers/membership.helper.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const heartTestCodes = [
  TestCode.DIRECT_LDL,
  TestCode.APO_B,
  TestCode.TG,
  TestCode.VLDL,
  TestCode.HDL,
  TestCode.LIPOPROTEIN_A,
  TestCode.HS_CRP,
]

const metabolicTestCodes = [
  TestCode.GLUCOSE, 
  TestCode.HBA1C, 
  TestCode.HOMAIR
]

const bpTestCodes = [
  TestCode.BP_S, 
  TestCode.BP_D, 
]

const liverTestCodes = [
  TestCode.ALP, 
  TestCode.ALT, 
  TestCode.AST, 
  TestCode.TOTAL_BILIRUBIN, 
  TestCode.DIRECT_BILIRUBIN, 
  TestCode.GGT,
]

const { Paragraph } = Typography
const { Item } = Form
const { confirm } = Modal

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const AdminHeartHealth = () => {
  const [memberships, setMemberships] = useState()
  const [filteredMemberships, setFilteredMemberships] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [tests, setTests] = useState()
  const [selectedPatientId, setSelectedPatientId] = useState()

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const { event, invitee } = e.data.payload
      const { uri: eventUri } = event
      const { uri: inviteeUri } = invitee

      const flow = await addProxyFlow({
        type: FlowType.HEART_MEMBER_CONSULT,
        user: selectedPatientId
      })
      
      const consultResponse = await addCalendlyConsult({
        flowId: flow._id,
        eventUri,
        inviteeUri
      })

      setMemberships(memberships.map(membership => {
        if (membership.patient._id === selectedPatientId) {
          let consults = membership.consults?.length ? membership.consults : []
          consults.push(consultResponse.consult)

          return {
            ...membership,
            consults
          }
        } else {
          return membership
        }
      }))

      setSelectedPatientId(null)

      message.info('Consult scheduled')
    },
  })

  useEffect(() => {
    document.title = 'Instalab | Heart Health'
    fetchTests()
  }, [])

  useEffect(() => {
    fetchMemberships()
  }, [tests])

  const fetchTests = async () => {
    setIsLoading(true)
    setTests(await listTests({
      select: '_id code name',
      filter: {
        code: {
          $in: [
            ...heartTestCodes,
            ...metabolicTestCodes,
            ...liverTestCodes,
            ...bpTestCodes,
          ]
        }
      }
    }))
  }


  const fetchMemberships = async () => {
    if (!tests?.length) return

    const data = await getData()

    const fetchedMemberships = data.map(membership => {
      return {
        ...membership,
        trend: getTrend(membership)
      }
    })
    setMemberships(fetchedMemberships)
    setFilteredMemberships(fetchedMemberships)
    setIsLoading(false)
  }

  const isNumber = (value) => {
    return typeof value === 'number' && !isNaN(value);
  }

  const getTrend = (membership) => {
    const heartTests = tests.filter(({ code }) => heartTestCodes.includes(code))
    const metabolicTests = tests.filter(({ code }) => metabolicTestCodes.includes(code))
    const liverTests = tests.filter(({ code }) => liverTestCodes.includes(code))
    const bpTests = tests.filter(({ code }) => bpTestCodes.includes(code))
    
    return membership.results.map(result => {
        return {
          collectedAt: moment(result.collectedAt).format('MM/DD/YY'),
          trends: {
            heart: getResultScore(result, heartTests, [TestCode.APO_B]),
            metabolism: getResultScore(result, metabolicTests),
            liver: getResultScore(result, liverTests),
            bp: getResultScore(result, bpTests),
          }
        }
      })
      .filter(({ trends }) => {
        return isNumber(trends.heart) || isNumber(trends.metabolism) || isNumber(trends.liver) || isNumber(trends.bp)
      })
  }

  const getResultScore = (result, tests, requiredTestCodes=[]) => {
    const RiskScore = {
      [RiskLevel.HIGH]: 0,
      [RiskLevel.MODERATE]: 1,
      [RiskLevel.OPTIMAL]: 2,
    }

    const isMissingRequiredTest = requiredTestCodes.some(code => {
      return !result.values.some(value => {
        const test = tests.find(t => t.code === code)
        return value.risk && value.test === test._id
      })
    })

    if (isMissingRequiredTest) {
      return null
    }

    const risks = result.values
      .filter(({ test }) => tests.some(({ _id }) => _id === test))
      .map(({ risk }) => risk)
      .filter(risk => risk)

    const resultScore = risks.reduce((acc, risk) => {
      acc += RiskScore[risk]
      return acc
    }, 0)/(risks.length * 2)*100

    return isNumber(resultScore) ? Math.round(resultScore) : null
  }

  const getActionItems = ({ _id }) => {
    let menuItems = []
    return menuItems
  }

  const isPharmacyPickup = prescription => {
    const prescriptionTypeCodes = [
      PrescriptionType.EVOLOCUMAB_140MG
    ]
    return prescriptionTypeCodes.includes(prescription.type)
  }

  const getRefillDate = prescription => {
    let { daysToRefill, gogo } = prescription
    if (gogo && !daysToRefill) {
      const { doseQty=1, units } = gogo
      daysToRefill = units / doseQty
    }
    if (daysToRefill) {
      // NOTE: medications that should be picked up at pharmacy should only have 1 day of buffer
      const shouldPickup = isPharmacyPickup(prescription)
      daysToRefill -= shouldPickup ? 7 : 14
      return moment(prescription.createdAt).add(daysToRefill, 'days').toDate()
    }
    return null
  }

  const PrescriptionItem = ({ prescription: defaultPrescription }) => {
    const [refillDate, setRefillDate] = useState()
    const [status, setStatus] = useState()
    const [isProcessing, setIsProcessing] = useState()
    const [prescription, setPrescription] = useState()

    useEffect(() => {
      setPrescription(defaultPrescription)
    }, [defaultPrescription])

    useEffect(() => {
      fetchData()
    }, [prescription])

    const fetchData = () => {
      if (!prescription) return
      const fetchedRefillDate = getRefillDate(prescription)
      setRefillDate(fetchedRefillDate)
      if (prescription.status !== PrescriptionStatus.PAUSED && fetchedRefillDate < new Date()) {
        setStatus('empty')
      } else {
        setStatus(prescription.status)
      }
    }

    const onRefill = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await refillPrescription(prescription._id))
        message.info('Prescription refilled')
      } catch (err) {
        message.error('Failed to refill')
      }
      setIsProcessing(false)
    }

    const onPause = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.PAUSED }))
        message.info(`Prescription paused`)
      } catch (err) {
        message.error('Failed to pause')
      }
      setIsProcessing(false)
    }

    const onRestart = async () => {
      setIsProcessing(true)
      try {
        setPrescription(await updatePrescriptionStatus(prescription._id, { status: PrescriptionStatus.SENT }))
        message.info(`Prescription restarted`)
      } catch (err) {
        message.error('Failed to restart')
      }
      setIsProcessing(false)
    }

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: [{
            key: 'refill',
            label: (
              <a onClick={onRefill}>
                <SyncOutlined style={{ marginRight: 5 }} /> Refill
              </a>
            )
          }, status !== PrescriptionStatus.PAUSED && {
            key: 'pause',
            label: (
              <a onClick={onPause}>
                <PauseCircleOutlined style={{ marginRight: 5 }} /> Pause
              </a>
            )
          }, status === PrescriptionStatus.PAUSED && {
            key: 'restart',
            label: (
              <a onClick={onRestart}>
                <PlayCircleOutlined style={{ marginRight: 5 }} /> Restart
              </a>
            )
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          title={(refillDate && status !== PrescriptionStatus.PAUSED) ? `Refill: ${moment(refillDate).format('MMM D, YYYY')}` : ''}
        >
          <div className="tag-container">
            <div className={classNames("tag", `${status}-tag`)}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {prescription?.type}
            </div>
          </div>
        </AntTooltip>    
      </Dropdown>
    )
  }

  const HeartTestKit = ({ kit: defaultKit, appointment }) => {
    const [kit, setKit] = useState()
    const [isProcessing, setIsProcessing] = useState()
    const weeksAgo = moment().diff(moment(kit?.createdAt), 'weeks')

    useEffect(() => {
      if (defaultKit) {
        setKit(defaultKit)
      }
    }, [defaultKit])

    const onMarkUnresponsive = async () => {
      setIsProcessing(true)

      try {
        const updatedKit = await updateProduct(kit._id, {
          fields: {
            status: ProductStatus.UNRESPONSIVE
          },
          select: 'status createdAt',
        })
        setKit(updatedKit)
        message.info('Mark unresponsive')
      } catch (err) {
        message.error('Failed to mark unresponsive')
      }

      setIsProcessing(false)
    }
    
    return kit && (
      <Dropdown
        overlayStyle={{
          width: 170
        }}
        menu={{
          items: [{
            key: 'unresponsive',
            label: (
              <a onClick={onMarkUnresponsive}>
                <FlagOutlined style={{ marginRight: 5 }} /> Mark Unresponsive
              </a>
            )
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          title={<>{kit.status}&nbsp; -&nbsp; <MailOutlined /> &nbsp;{weeksAgo} {weeksAgo === 1 ? 'week' : 'weeks'} ago</>}
        >
          <div 
            className="tag-container"
            id={`kit-${kit._id}`}
          >
            <div className={classNames("tag", kit?.status === ProductStatus.UNRESPONSIVE ? 'unresponsive-kit-tag' : weeksAgo >= 2 ? `outdated-tag` : 'pending-kit-tag')}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(kit.createdAt).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const LongevityAppointment = ({ appointment }) => {
    const [isProcessing, setIsProcessing] = useState()
    const weeksLater = moment(appointment.start).diff(moment(), 'weeks')
    
    return appointment && (
      <Dropdown
        overlayStyle={{
          width: 170
        }}
        menu={{
          items: []
        }}
        placement='bottom'
      >
        <AntTooltip
          title={<>blood draw &nbsp;- &nbsp; <CalendarOutlined />&nbsp; {weeksLater} {weeksLater === 1 ? 'week' : 'weeks'} later</>}
        >
          <div 
            className="tag-container"
            id={`appointment-${appointment._id}`}
          >
            <div className={classNames("tag", "appointment-tag")}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(appointment.start).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const LipidTestItem = ({ result, patient, index, hasKit, hasAppointment }) => {
    const [isProcessing, setIsProcessing] = useState()
    const shouldRetest = !hasKit && !hasAppointment && index === 0 && moment(result.collectedAt).add(5, 'weeks') < moment()
    const weeksAgo = moment().diff(moment(result.collectedAt), 'weeks')

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: []
        }}
        placement='bottom'
      >
        <AntTooltip
          title={`${weeksAgo} ${weeksAgo === 1 ? 'week' : 'weeks'} ago`}
        >
          <div className="tag-container">
            <div className={classNames("tag", `${shouldRetest ? 'outdated' : 'previous'}-tag`)}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(result.collectedAt).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const ConsultItem = ({ consult }) => {
    const [isProcessing, setIsProcessing] = useState()
    const weeksAgo = moment().diff(moment(consult.start), 'weeks', true)

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: []
        }}
        placement='bottom'
      >
        <AntTooltip
          overlayStyle={{
            maxWidth: 500
          }}
          title={(consult.reason || consult.questions?.length) ? <>
            <Paragraph className="consult-title">
              {Math.abs(Math.round(weeksAgo))} {Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} {weeksAgo > 0 ? 'ago' : 'later'}
            </Paragraph>

            {consult.reason && (
              <Paragraph className="consult-reason">
                {consult.reason}
              </Paragraph>
            )}

            {consult.questions?.map(({ question, answer }, questionIndex) => (
              <div key={`consult-${consult._id}-${questionIndex}`}>
                <Paragraph className="question-title">
                  {question}
                </Paragraph>
                <Paragraph className="question-answer">
                  {answer}
                </Paragraph>
              </div>
            ))}
          </> : `${Math.abs(Math.round(weeksAgo))} ${Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} ${weeksAgo > 0 ? 'ago' : 'later'}`}
        >
          <div className="tag-container">
            <div className={classNames("tag", weeksAgo > 0 ? "past-consult-tag" : "future-consult-tag")}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(consult.start).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const ReminderItem = ({ reminder, onEditReminder, onRemoveReminder, onChangeReminderStatus }) => {
    const [isProcessing, setIsProcessing] = useState()
    const weeksAgo = moment().diff(moment(reminder.scheduledAt), 'weeks', true)

    return (
      <Dropdown
        overlayStyle={{
          width: 150
        }}
        menu={{
          items: [reminder.status === ReminderStatus.PENDING ? {
            key: 'complete',
            label: (
              <a onClick={() => onChangeReminderStatus(reminder._id, true)}>
                <CheckCircleOutlined style={{ marginRight: 5 }} /> Complete
              </a>
            )
          } : {
            key: 'open',
            label: (
              <a onClick={() => onChangeReminderStatus(reminder._id, false)}>
                <BorderOutlined style={{ marginRight: 5 }} /> Open
              </a>
            )
          }, {
            key: 'edit',
            label: (
              <a onClick={() => onEditReminder(reminder._id)}>
                <EditOutlined style={{ marginRight: 5 }} /> Edit
              </a>
            )
          }, {
            key: 'remove',
            label: (
              <a onClick={() => onRemoveReminder(reminder._id)}>
                <DeleteOutlined style={{ marginRight: 5 }} /> Remove
              </a>
            )
          }]
        }}
        placement='bottom'
      >
        <AntTooltip
          overlayStyle={{
            maxWidth: 500
          }}
          title={<>
            <Paragraph className="reminder-title">
              {Math.abs(Math.round(weeksAgo))} {Math.abs(Math.round(weeksAgo)) === 1 ? 'week' : 'weeks'} {weeksAgo > 0 ? 'ago' : 'later'}
            </Paragraph>

            <Paragraph className="reminder-text">
              {reminder.text}
            </Paragraph>
          </>}
        >
          <div className="tag-container">
            <div className={classNames("tag", reminder.status === ReminderStatus.COMPLETE ? "complete-reminder-tag" : (weeksAgo >= 0 && reminder.status === ReminderStatus.PENDING) ? "overdue-reminder-tag" : weeksAgo > 0 ? "past-reminder-tag" : "future-reminder-tag")}>
              {isProcessing && <Spin className="tag-spin" size='small' />}
              {moment(reminder.scheduledAt).format('MMM D, YYYY')}
            </div>
          </div>
        </AntTooltip>
      </Dropdown>
    )
  }

  const NoteDrawer = ({ notes: defaultNotes, patient }) => {
    const [open, setOpen] = useState()
    const [openNoteForm, setOpenNoteForm] = useState()
    const [noteForm] = Form.useForm()
    const [isLoading, setIsLoading] = useState()
    const { currentUser } = useContext(UserContext)
    const [notes, setNotes] = useState([])
    const [selectedNoteId, setSelectedNoteId] = useState()

    useEffect(() => {
      setNotes(defaultNotes)
    }, [defaultNotes])
    
    const showDrawer = () => {
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
    };

    const onAddNote = async () => {
      setIsLoading(true)

      try {
        const { text } = noteForm.getFieldsValue()
        const response = await addNote({
          text,
          author: currentUser._id,
          patient: patient._id
        })
        setNotes([
          response,
          ...notes
        ])
        message.info('Note added')
        noteForm.resetFields()
      } catch (err) {
        message.error('Failed to add note')
      }

      setIsLoading(false)
    }

    const onEditClick = async (noteId) => {
      setSelectedNoteId(noteId)
      setOpenNoteForm(true)
    }

    const onRemoveClick = async (noteId) => {
      confirm({
        title: 'Sure you want to delete this note?',
        content: 'This action cannot be undone.',
        onOk() {
          onRemoveNote(noteId)
        },
        okText: 'Yes',
        cancelText: 'No',
      });
    }
    
    const onRemoveNote = async (noteId) => {
      try {
        setNotes(notes.filter(({ _id }) => _id !== noteId))
        message.info('Note removed')
      } catch (err) {
        message.error('Failed to remove note')
      }
    }

    return <>
      <Button
        className="notes-btn"
        onClick={showDrawer}
      >
        Notes {notes?.length > 0 && <span className="note-count">{notes.length}</span>}
      </Button>

      <NoteForm
        open={openNoteForm}
        setOpen={setOpenNoteForm}
        onSuccess={note => {
          setNotes(notes.map(n => n._id === note._id ? note : n))
        }}
        noteId={selectedNoteId}
        patientId={patient._id}
        currentUserId={currentUser._id}
      />

      <Drawer 
        title={<>Notes&nbsp;&nbsp;|&nbsp;&nbsp;{patient.firstName} {patient.lastName}</>}
        onClose={onClose} 
        open={open}
        width={600}
        className='note-drawer'
      >
        <Form
          form={noteForm}
          onFinish={onAddNote}
          layout='vertical'
        >
          <Item 
            name="text"
            className="text-item"
          >
            <ReactQuill theme="snow" modules={modules} />
          </Item>

          <Item>
            <Button
              htmlType='submit'
              type="primary" 
              loading={isLoading}
            >
              Save Note
            </Button>
          </Item>
        </Form>

        {notes?.map(note => (
          <div 
            key={`note-${note._id}`}
            className="note-item"
          >
            <Paragraph className="note-text">
              {parse(note.text.replace(/<p><br><\/p>/g, ""))}
            </Paragraph>
            <div className="note-footer">
              {note.author.firstName} {note.author.lastName}&nbsp;&nbsp;•&nbsp;&nbsp;{moment(note.createdAt).format('MMM D, YYYY')}&nbsp;&nbsp;•&nbsp;&nbsp;<a className="edit-note-link" onClick={() => onEditClick(note._id)}>Edit</a>&nbsp;&nbsp;•&nbsp;&nbsp;<a className="remove-note-link" onClick={() => onRemoveClick(note._id)}>Remove</a>
            </div>
          </div>
        ))}
      </Drawer>
    </>
  }

  const SendHeartTest = ({ results, patient, kit: defaultKit, appointment }) => {
    const [isSending, setIsSending] = useState()
    const [kit, setKit] = useState()

    useEffect(() => {
      if (defaultKit) {
        setKit(defaultKit)
      }
    }, [defaultKit])

    const onSend = async () => {
      setIsSending(true)
      try {
        const params = {
          fields: {
            free: true,
            type: ProductTypeCode.HEART_HEALTH_TEST_KIT,
            facility: null,
            start: null,
            patient: patient._id
          },
          select: 'createdAt status',
        }
        const product = await addProduct(params)
        setKit(product)
        message.info('Test kit sent')
      } catch (err) {
        message.error('Failed to send test kit')
      }
      setIsSending(false)
    }

    return <>
      <Button
        className="add-btn"
        loading={isSending}
        onClick={onSend}
        icon={<PlusOutlined />}
      />

      {appointment && (
        <LongevityAppointment 
          appointment={appointment}
        />
      )}

      {kit && (
        <HeartTestKit 
          kit={kit}
          appointment={appointment}
        />
      )}
      
      {results.map((result, index) => (
        <LipidTestItem
          key={`lipid-${result._id}`}
          result={result}
          patientId={patient._id}
          index={index}
          hasKit={kit}
          hasAppointment={appointment}
        />
      ))}
    </>
  }

  const ConsultList = ({ consults: defaultConsults, patient }) => {
    const [consults, setConsults] = useState()
    const [openCalendly, setOpenCalendly] = useState()

    useEffect(() => {
      if (defaultConsults) {
        setConsults(defaultConsults)
      }
    }, [defaultConsults])

    const onAddConsult = () => {
      setSelectedPatientId(patient._id)
      setOpenCalendly(true)
    }

    return <>
      <Button
        className="add-btn"
        onClick={onAddConsult}
        icon={<PlusOutlined />}
      />

      <PopupModal
        open={openCalendly}
        url={process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest-clone' : "https://calendly.com/d/3r9-gjk-xjv/heart-health-consultation"}
        onModalClose={() => setOpenCalendly(false)}
        rootElement={document.getElementById("root")}
        pageSettings={{
          primaryColor: Color.success,
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
        }}
        prefill={{
          firstName: patient?.firstName ? patient.firstName : '',
          lastName: patient?.lastName ? patient.lastName : '',
          name: patient?.firstName ? `${patient.firstName} ${patient.lastName}` : '',
          email: patient?.email ? patient.email : '',
        }}
      />

      {consults?.map(consult => (
        <ConsultItem
          key={`consult-${consult._id}`}
          consult={consult}
        />
      ))}
    </>
  }

  const ReminderList = ({ reminders: defaultReminders, patient }) => {
    const [reminders, setReminders] = useState([])
    const [selectedReminderId, setSelectedReminderId] = useState()
    const [openForm, setOpenForm] = useState()

    useEffect(() => {
      if (defaultReminders) {
        setReminders(defaultReminders)
      }
    }, [defaultReminders])

    const onAddReminder = () => {
      setOpenForm(true)
    }

    const sortReminders = reminderList => {
      return reminderList.sort((a, b) => {
        return new Date(b.scheduledAt).getTime() - new Date(a.scheduledAt).getTime()
      })
    }

    const onRemoveReminder = async (reminderId) => {
      confirm({
        title: 'Sure you want to delete this reminder?',
        content: 'This action cannot be undone.',
        onOk: async () => {
          await removeReminder(reminderId)
          setReminders(reminders.filter(({ _id }) => _id !== reminderId))
          message.info('Reminder removed')
        },
        okText: 'Yes',
        cancelText: 'No',
      });
    }

    const onEditReminder = (reminderId) => {
      setSelectedReminderId(reminderId)
      setOpenForm(true)
    }

    const onChangeReminderStatus = async (reminderId, isComplete) => {
      try {
        const updatedReminder = await updateReminder(reminderId, {
          fields: {
            status: isComplete ? ReminderStatus.COMPLETE : ReminderStatus.PENDING,
            completeAt: isComplete ? new Date() : null
          },
          select: 'text patient status scheduledAt completeAt',
        })
        setReminders(reminders.map(reminder => {
          return reminder._id === reminderId ? updatedReminder : reminder
        }))
        message.info(`Reminder ${isComplete ? 'completed' : 'opened'}`)
      } catch (err) {
        message.error(`Failed to ${isComplete ? 'complete' : 'open'} reminder`)
      }
    }

    return <>
      <Button
        className="add-btn"
        onClick={onAddReminder}
        icon={<PlusOutlined />}
      />

      <ReminderForm
        open={openForm}
        setOpen={setOpenForm}
        reminderId={selectedReminderId}
        setReminderId={setSelectedReminderId}
        select="text patient status scheduledAt completeAt"
        patientId={patient._id}
        onSuccess={reminder => {
          if (reminders?.some(({ _id }) => _id === reminder._id)) {
            setReminders(sortReminders(reminders.map(r => r._id === reminder._id ? reminder : r)))
          } else {
            setReminders(sortReminders([
              ...reminders,
              reminder
            ]))
          }
        }}
      />

      {reminders.map(reminder => (
        <ReminderItem
          key={`reminder-${reminder._id}`}
          reminder={reminder}
          onEditReminder={onEditReminder}
          onRemoveReminder={onRemoveReminder}
          onChangeReminderStatus={onChangeReminderStatus}
        />
      ))}
    </>
  }

  return (
    <div className="admin-heart-health">
      <PageHeader
        title='Heart Health'
        count={filteredMemberships?.filter(membership => MembershipHelper.isActive(membership))?.length}
        actions={(
          <Select
            placeholder="Select filter..."
            options={[{
              label: 'Overdue',
              value: 'overdue'
            }]}
            style={{ width: 200 }}
            onChange={value => {
              if (value === 'overdue') {
                setFilteredMemberships(memberships.filter(membership => {
                  // Overdue reminders
                  if (membership.reminders.some(reminder => {
                    const weeksAgo = moment().diff(moment(reminder.scheduledAt), 'weeks', true)
                    return weeksAgo >= 0 && reminder.status === ReminderStatus.PENDING
                  })) {
                    return true
                  }

                  // Overdue lipid tests
                  if (membership.kit && moment().diff(moment(membership.kit.createdAt), 'weeks') >= 2) {
                    return true
                  }

                  // Overdue prescriptions
                  const prescriptionTypes = membership.prescriptions.reduce((acc, prescription) => {
                    if (!acc.includes(prescription.type)) {
                      acc.push(prescription.type)
                    }
                    return acc
                  }, [])
                  if (prescriptionTypes.some(prescriptionType => {
                    const filteredPrescriptions = membership.prescriptions
                      .filter(({ type }) => type === prescriptionType)
                      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
                    const prescription = filteredPrescriptions[0]
                    const refillDate = getRefillDate(prescription)
                    return prescription.status !== PrescriptionStatus.PAUSED && refillDate < new Date()
                  })) {
                    return true
                  }

                  return false
                }))
              } else {
                setFilteredMemberships(memberships)
              }
            }}
            allowClear
          />
        )}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={filteredMemberships}
        getActionItems={getActionItems}
        rowClassName={membership => {
          return MembershipHelper.isActive(membership) ? 'active-membership' : 'inactive-membership'
        }}
        tableProps={{
          pagination: false,
        }}
        columns={[{
          title: 'Patient',
          dataIndex: 'patient',
          width: 150,
          render: patient => {
            return <a onClick={() => window.open(`/patients/${patient._id}?key=Results`, '_blank')}>{patient.firstName} {patient.lastName}</a>
          }
        }, {
          title: 'Prescriptions',
          dataIndex: 'prescriptions',
          width: 180,
          render: membershipPrescriptions => {
            if (!membershipPrescriptions?.length) return

            const prescriptionTypes = membershipPrescriptions.reduce((acc, prescription) => {
              if (!acc.includes(prescription.type)) {
                acc.push(prescription.type)
              }
              return acc
            }, [])
            return prescriptionTypes.map(prescriptionType => {
              const filteredPrescriptions = membershipPrescriptions
                .filter(({ type }) => type === prescriptionType)
                .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              const prescription = filteredPrescriptions[0]
              return (
                <PrescriptionItem 
                  key={prescription._id} 
                  prescription={prescription} 
                />
              )
            })
          }
        }, {
          title: 'Lipid Tests',
          dataIndex: 'lipidResults',
          width: 120,
          render: (lipidResults, { patient, kit, appointment }) => (
            <SendHeartTest 
              results={lipidResults} 
              patient={patient} 
              kit={kit}
              appointment={appointment}
            />
          )
        }, {
          title: 'Consults',
          dataIndex: 'consults',
          width: 120,
          render: (consults, { patient }) => (
            <ConsultList 
              consults={consults} 
              patient={patient}
            />
          )
        }, {
          title: 'Reminders',
          dataIndex: 'reminders',
          width: 120,
          render: (reminders, { patient }) => (
            <ReminderList 
              reminders={reminders} 
              patient={patient}
            />
          )
        }, {
          title: 'Trend',
          dataIndex: 'trend',
          render: (trend, { _id, prescriptionEvents, patient }) => (
            <HeartTrendLine 
              trend={trend} 
              chartId={_id} 
              prescriptionEvents={prescriptionEvents} 
              patient={patient}
            />
          )
        }, {
          title: 'Notes',
          dataIndex: 'notes',
          width: 50,
          render: (notes, { patient }) => (
            <NoteDrawer 
              notes={notes} 
              patient={patient}
            />
          )
        }]}
      />
    </div>
  )
}
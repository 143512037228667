import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import StepType from "../flow/enums/stepType.enum"
import FlowType from "../../enums/flowType.enum.js"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmGift } from "./confirmGift.component"
import { getProductTypeByCode } from "../../services/productType.service"
import { completeFlow } from "../../services/flow.service.js"
import { listProductTypes } from '../../services/productType.service'
import moment from "moment"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  SELECT: 'select',
  PAY_LONGEVITY_PANEL: 'pay-longevity-panel',
  PAY_GALLERI: 'pay-galleri',
  RECIPIENT: 'recipient',
  MESSAGE: 'message',
  SEND_DATE: 'send-date',
  CONFIRM: 'confirm',
}

export const GiftFlow = () => {
  const navigate = useNavigate()
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()

  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchProductTypes()
  }, [])


  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GIFT_GALLERI,
            ProductTypeCode.GIFT_LONGEVITY_PANEL
          ]
        }
      }
    }))
  }


  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }


  useEffect(() => {
    fetchSkeleton()
  }, [flow, hasPass, initialUser])


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage your orders. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.GIFT}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass 
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.SELECT,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.SELECT]: {
        type: StepType.PRODUCT_SELECT,
        title: `Select gift for your friend or loved one.`,
        description: `Please note recipients must be 18 years or older.`,
        buildUser: true,
        multiple: false,
        onNextStep: (patient) => {
          switch (patient?.selectedGift) {
            case getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code:
              return Step.PAY_LONGEVITY_PANEL
            case getProductType(ProductTypeCode.GIFT_GALLERI)?.code:
              return Step.PAY_GALLERI
        
            default:
              break;
          }
        },
        field: {
          name: 'selectedGift',
          required: true,
          options: [
            {
              title: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title,
              value: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
              description: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.tagline,
              tag: null,
              price: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost
            }, 
            {
              title: getProductType(ProductTypeCode.GIFT_GALLERI)?.title,
              value: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
              description: getProductType(ProductTypeCode.GIFT_GALLERI)?.tagline,
              tag: null,
              price: getProductType(ProductTypeCode.GIFT_GALLERI)?.cost
            }
          ]
        },
        isLocked: () => {
          return flow?.products?.length
        }
      },
      [Step.PAY_LONGEVITY_PANEL]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.code,
        nextStep: Step.RECIPIENT,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_LONGEVITY_PANEL)?.title}.</>,
        description: `After paying, you’ll add the email for your gift recipient. We'll email them their gift on the specific date you choose.`,
      },
      [Step.PAY_GALLERI]: {
        type: StepType.PAY,
        addProduct: true,
        productType: getProductType(ProductTypeCode.GIFT_GALLERI)?.code,
        nextStep: Step.RECIPIENT,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.GIFT_GALLERI)?.cost}</span> for your gift: {getProductType(ProductTypeCode.GIFT_GALLERI)?.title}.</>,
        description: `After paying, you’ll add the email for your gift recipient. We'll email them their gift on the specific date you choose.`,
      },
      [Step.RECIPIENT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.MESSAGE,
        buildFlow: true,
        title: <>Tell us about your gift receipient.</>,
        description: <>We'll ask you to write a message to your recipient in the next step.</>,
        fields: [
          {
            name: 'recipientFirstName',
            label: 'First Name',
            placeholder: 'First Name',
            required: true,
          }, 
          {
            name: 'recipientLastName',
            label: 'Last Name',
            placeholder: 'Last Name',
            required: true,
          },
          {
            name: 'recipientEmail',
            label: 'Email Address',
            placeholder: 'Type email here...',
            email: true,
            required: true,
          }, 
        ],
      },
      [Step.MESSAGE]: {
        type: StepType.TEXTAREA,
        nextStep: Step.SEND_DATE,
        buildFlow: true,
        title: `Please write message to your gift recipient.`,
        description: `This note will be accompanied with your gift.`,
        field: {
          name: 'giftMessage',
          placeholder: 'Type message here...',
        },
      },
      [Step.SEND_DATE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildFlow: true,
        title: `Which date do you want your gift sent?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'giftActivationDate',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
          initialValue: moment().format('MM/DD/YYYY')

        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You’re all set! 🎉</>,
        description: <>Your gift has been scheduled and will be emailed on your selected date. Thank you for choosing the gift of health – it’s one of the most thoughtful and impactful gifts you can give!<br/><br/>If you have any questions or need further assistance, please reach out by chat or email concierge@instalab.com.</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmGift />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }
  return (
     <Flow 
      skeleton={skeleton} 
      flow={flow}
      setFlow={setFlow}
      setHasPass={setHasPass}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
    />
  )
}
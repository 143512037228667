import React, { useContext, useEffect, useState } from 'react';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { EditOutlined, EnvironmentOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Tag, Modal, Input } from 'antd';
import { listFacilities, deleteFacility } from "../../services/facility.service.js";
import { listProductTypes } from "../../services/productType.service.js";  
import './adminFacilities.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import ObjectType from '../../enums/objectType.enum';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import { FacilityForm } from "./facilityForm.component.js";
import Highlighter from 'react-highlight-words';
import FacilityStatus from '../../enums/facilityStatus.enum.js';
import Role from '../../enums/role.enum.js';

const select = '_id name location menu status';

export const AdminFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [facilityId, setFacilityId] = useState();
  const [productTypes, setProductTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAdminLocationModalOpen, setIsAdminLocationModalOpen] = useState();
  const [filteredCount, setFilteredCount] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { setCounts, currentUser } = useContext(UserContext);
  const searchInput = React.useRef(null);


  useEffect(() => {
    document.title = 'Instalab | Facilities'
    fetchFacilities();
    fetchProductTypes();
  }, []);

  const fetchFacilities = async () => {
    setIsLoading(true);
    let params = {
      select,
      filter: {},
      populate: [{
        path: 'menu', 
        populate: 'productType'
      }],
      sort: 'name'
    };

    const fetchedFacilities = await listFacilities(params);
    setFacilities(fetchedFacilities);
    setFilteredCount(fetchedFacilities.length);
    setIsLoading(false);
  };

  const fetchProductTypes = async () => {
    const productTypes = await listProductTypes({ 
      filter: { isActive: true, hasAppointment: true },
      sort: 'title' 
    });
    setProductTypes(productTypes);
  };

  const handleDeleteFacility = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this facility?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteFacility(id);
          setFacilities(facilities.filter(facility => facility._id !== id));
          setFilteredCount(prevCount => prevCount - 1);
          setCounts(prevCounts => ({
            ...prevCounts,
            facilities: prevCounts.facilities - 1
          }));
        } catch (error) {
          console.error('Failed to delete facility:', error);
        }
      }
    });
  };

  const getActionItems = ({ _id }) => [
    {
      key: '1',
      label: (
        <Button onClick={() => {
          setFacilityId(_id);
          setIsEditModalOpen(true);
        }}>
          <EditOutlined /> Edit
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button onClick={() => {
          setFacilityId(_id);
          setIsAdminLocationModalOpen(true);
        }}>
          <EnvironmentOutlined /> Set Location
        </Button>
      )
    },
    currentUser.role === Role.ADMIN && {
      type: 'divider'
    },
    currentUser.role === Role.ADMIN && {
      key: '3',
      label: (
        <Button
          danger
          onClick={() => handleDeleteFacility(_id)}
        >
          <DeleteOutlined /> Remove
        </Button>
      )
    }
  ];

  const formatLocation = location => {
    if (!location) return null
    return `${location.streetAddress}${location.streetAddress2 ? ' ' + location.streetAddress2 : ''}, ${location.city}, ${location.state} ${location.postalCode}`;
  };

  // Custom search logic for address column
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => formatLocation(record[dataIndex])?.toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={formatLocation(text) ? formatLocation(text).toString() : ''}
        />
      ) : (
        formatLocation(text)
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  // Custom filter function for the menu items
  const handleMenuFilter = (selectedProductTypes, record) => {
    if (!selectedProductTypes.length) return true;  // If no product types selected, show all
    return record.menu.some(item => selectedProductTypes.includes(item.productType._id));
  };

  // Custom filter function for status
  const handleStatusFilter = (value, record) => record.status === value;

  // Render the status with colored tags
  const renderStatus = (status) => {
    const color = status === FacilityStatus.ACTIVE ? 'success' : 'default';
    return <Tag color={color}>{status}</Tag>;
  };
  

  const renderMenu = (menu) => {
    if (!menu || menu.length === 0) return 'No menu items';
    
    return menu.map(item => {
      // Check if price is a whole number (i.e., no decimal places)
      const formattedPrice = item.price % 1 === 0 ? `$${item.price}` : `$${item.price.toFixed(2)}`;
      
      return (
        <Tag key={item.productType._id}>
          {`${item.productType.title} - ${formattedPrice}`}
        </Tag>
      );
    });
  };

  return (
    <div className="facility-facilities">
      <PageHeader
        title='Facilities'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setFacilityId(null);
              setIsEditModalOpen(true);
            }}
          >
            + Add New Facility
          </Button>
        )}
      />

      <AdminLocationModal
        objectId={facilityId}
        objectType={ObjectType.FACILITY}
        open={isAdminLocationModalOpen}
        setOpen={setIsAdminLocationModalOpen}
        select={select}
        onSuccess={facility => {
          setFacilities(facilities.map(p => p._id === facilityId ? facility : p));
        }}
      />

      <FacilityForm
        facilityId={facilityId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={facility => {
          if (facilityId) {
            setFacilities(facilities.map(p => p._id === facilityId ? facility : p));
          } else {
            setFacilities([facility, ...facilities]);
            setFilteredCount(cachedFilterCount => cachedFilterCount + 1);
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                facilities: cachedCounts.facilities + 1
              };
            });
          }
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={facilities}
        setFilteredCount={setFilteredCount}
        getActionItems={getActionItems}
        columns={[
          {
            title: 'Facility',
            dataIndex: 'name',
            filterDropdownType: FilterDropdownType.INPUT,
          },
          {
            title: 'Address',
            dataIndex: 'location',
            ...getColumnSearchProps('location'),  // Use search props for address column
          },
          {
            title: 'Menu',
            dataIndex: 'menu',
            render: renderMenu,
            filterDropdownType: FilterDropdownType.MULTISELECT,
            filters: productTypes.map(pt => ({ text: pt.title, value: pt._id })),
            onFilter: handleMenuFilter,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: renderStatus,
            filters: Object.values(FacilityStatus).map(status => ({
              text: status,
              value: status
            })),
            onFilter: handleStatusFilter,
            filterDropdownType: FilterDropdownType.MULTISELECT,
          },
        ]}
      />
    </div>
  );
};
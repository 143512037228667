import parse from 'html-react-parser';
import InfiniteScroll from 'react-infinite-scroll-component';
import "./heartHealthEvents.scss";
import { useContext, useEffect, useState, useCallback } from "react";
import moment from 'moment'
import Color from '../../colors.scss';
import { Tooltip, Affix, Select, Modal, Timeline, Typography, Avatar, Spin, Checkbox, message } from 'antd'
import { listEvents, listPatients } from "../../services/heart.service";
import { useSearchParams } from 'react-router-dom'
import HeartEventType from "../../enums/heartEventType.enum"
import classNames from 'classnames';
import { NoteForm } from '../noteForm/noteForm.component';
import { UserContext } from '../../contexts/user.context';
import { removeNote } from '../../services/note.service';
import { removeReminder, updateReminder } from '../../services/reminder.service';
import { ReminderForm } from '../reminderForm/reminderForm.component';
import ReminderStatus from '../../enums/reminderStatus.enum';
import ImageMap from '../../enums/imageMap.enum';
import { debounce } from 'lodash';

const { Text, Paragraph } = Typography
const { confirm } = Modal

const limit = 50

export const HeartHealthEvents = () => {
  const { currentUser } = useContext(UserContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const [openNoteForm, setOpenNoteForm] = useState()
  const [events, setEvents] = useState([])
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [total, setTotal] = useState(0)
  const [selectedNote, setSelectedNote] = useState()
  const [patients, setPatients] = useState([])
  const [selectedReminderId, setSelectedReminderId] = useState()
  const [openReminderForm, setOpenReminderForm] = useState()
  const [filter, setFilter] = useState()

  useEffect(() => {
    fetchPatients()
  }, [])

  useEffect(() => {
    fetchEvents(false)
  }, [page])

  useEffect(() => {
    if (filter) {
      fetchEvents(true)
    }
  }, [filter])

  useEffect(() => {
    setFilter({
      patient: searchParams.get('patient'),
      eventType: searchParams.get('eventType'),
    })
  }, [searchParams])

  const debounceFetchEvents = useCallback(
    debounce(async (debouncedFilter, debouncedPage, shouldClear) => {
      try {
        const updatedPage = shouldClear ? 1 : debouncedPage

        if (shouldClear) {
          setEvents([])
          setPage(updatedPage)
          setHasMore(true)
        }

        let params = { 
          limit, 
          page: updatedPage, 
          filter: debouncedFilter 
        }

        const eventsResponse = await listEvents(params)

        const fetchedEvents = eventsResponse.events
        
        setTotal(eventsResponse.total)
        if (eventsResponse.total <= 50) {
          setHasMore(false)
        }

        if (fetchedEvents.length === 0) {
          setHasMore(false)
        } else {
          setEvents(prevEvents => {
            if (prevEvents?.length) {
              const nonDuplicateEvents = fetchedEvents.filter(({ data, type, timestamp }) => {
                return !prevEvents.some(event => event.data._id === data._id && event.type === type && new Date(event.timestamp).getTime() === new Date(timestamp).getTime());
              });
              return [...prevEvents, ...nonDuplicateEvents]
            } else {
              return fetchedEvents
            }
          })
        }
      } catch (err) {
        console.error('fetch events error', err)
      }
    }, 500), []
  );

  const Bullet = () => {
    return <Text className="event-footer-bullet">•</Text>
  }

  const PatientLink = ({ patient }) => {
    return (
      <a 
        className="patient-link"
        onClick={() => window.open(`/patients/${patient?._id}`, '_blank')}
      >
        {patient?.firstName} {patient?.lastName}
      </a>
    )
  }

  const TrackingLink = ({ trackingNumber }) => {
    return (
      <a 
        className="tracking-link"
        onClick={() => window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`, '_blank')}
      >
        View Tracking
      </a>
    )
  }

  const EventTimestamp = ({ timestamp }) => {
    return (
      <Text className="event-timestamp">
        {moment(timestamp).format('MMM DD, YYYY')}
      </Text>
    )
  }

  const fetchPatients = async () => {
    const fetchedPatients = await listPatients()
    setPatients(fetchedPatients.sort((a, b) => {
      let nameA = (a.firstName + " " + a.lastName).toLowerCase();
      let nameB = (b.firstName + " " + b.lastName).toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }))
  }

  const fetchEvents = (shouldClear=false) => {
    debounceFetchEvents(filter, page, shouldClear)
  }

  const fetchMoreEvents = () => {
    setPage(page+1)
  }

  const onChangeReminderStatus = async (reminderId, isComplete) => {
    try {
      const updatedReminder = await updateReminder(reminderId, {
        fields: {
          status: isComplete ? ReminderStatus.COMPLETE : ReminderStatus.PENDING,
          completeAt: isComplete ? new Date() : null
        },
        select: 'text scheduledAt completeAt status',
        populate: [{
          path: 'author',
          select: 'firstName lastName'
        }, {
          path: 'patient',
          select: 'firstName lastName'
        }]
      })
      setEvents(events.map(event => {
        if (event.data._id === updatedReminder._id) {
          return {
            ...event,
            data: updatedReminder,
            timestamp: isComplete ? updatedReminder.completeAt : updatedReminder.scheduledAt
          }
        }
        return event
      }))
      message.info(`Reminder ${isComplete ? 'completed' : 'opened'}`)
    } catch (err) {
      message.error(`Failed to ${isComplete ? 'complete' : 'open'} reminder`)
    }
  }

  const onRemoveReminderClick = (reminderId) => {
    confirm({
      title: 'Sure you want to delete this reminder?',
      content: 'This action cannot be undone.',
      onOk() {
        onRemoveReminder(reminderId)
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  }

  const onRemoveReminder = async (reminderId) => {
    try {
      await removeReminder(reminderId)
      setEvents(events.filter(event => event.data._id !== reminderId))
      message.info('Reminder removed')
    } catch (err) {
      message.error('Failed to remove reminder')
    }
  }

  const onRemoveNoteClick = (noteId) => {
    confirm({
      title: 'Sure you want to delete this note?',
      content: 'This action cannot be undone.',
      onOk() {
        onRemoveNote(noteId)
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  }

  const onRemoveNote = async (noteId) => {
    try {
      await removeNote(noteId)
      setEvents(events.filter(event => event.data._id !== noteId))
      message.info('Note removed')
    } catch (err) {
      message.error('Failed to remove note')
    }
  }

  const Note = ({ event }) => {
    const note = event.data

    return (
      <div className="note" id={note?._id}>
        <Paragraph className="event-bubble">
          {parse(note.text.replace(/<p><br><\/p>/g, ""))}
        </Paragraph>
        
        <div className="event-footer">
          <PatientLink patient={note.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
          <Bullet />

          <a 
            className="event-action"
            onClick={() => {
              setSelectedNote(event.data)
              setOpenNoteForm(true)
            }}
          >
            Edit
          </a>

          <Bullet />

          <a 
            className="event-action"
            onClick={() => {
              onRemoveNoteClick(event.data._id)
            }}
          >
            Delete
          </a>

          <Tooltip title={`${note.author.firstName} ${note.author.lastName}`}>
            <Avatar 
              src={ImageMap[note.author._id]} 
              size="small"
              className="author-avatar"
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  const Reminder = ({ event }) => {
    const reminder = event.data

    return (
      <div className="reminder">
        <Paragraph className="event-bubble">
          <Checkbox 
            className="reminder-checkbox" 
            defaultChecked={reminder.status === ReminderStatus.COMPLETE} 
            onChange={e => onChangeReminderStatus(reminder._id, e.target.checked)}
          /> 
          <div>
            <strong>Reminder:</strong> {reminder.text}
          </div>
        </Paragraph>
        
        <div className="event-footer">
          <PatientLink patient={reminder.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
          <Bullet />

          <a 
            className="event-action"
            onClick={() => {
              setSelectedReminderId(event.data._id)
              setOpenReminderForm(true)
            }}
          >
            Edit
          </a>

          <Bullet />

          <a 
            className="event-action"
            onClick={() => {
              onRemoveReminderClick(event.data._id)
            }}
          >
            Delete
          </a>

          <Tooltip title={`${reminder.author.firstName} ${reminder.author.lastName}`}>
            <Avatar 
              src={ImageMap[reminder.author._id]} 
              size="small"
              className="author-avatar"
            />
          </Tooltip>
        </div>
      </div>
    )
  }

  const ResulReturned = ({ event }) => {
    const result = event.data
    
    return (
      <div className="result-returned">
        <div className={classNames("event-bubble")}>
          <Text>
            <strong>Result returned:</strong> {moment(result.collectedAt).format('MMM DD, YYYY')}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={result.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const KitOrdered = ({ event }) => {
    const kit = event.data
    
    return (
      <div className="kit-created">
        <div className={classNames("event-bubble", "kit-details")}>
          <img 
            src={ImageMap[kit.productType?._id]} 
            className="kit-img"
          />
          <Text>
            <strong>Ordered:</strong> {kit.productType?.title}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={kit.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const KitTracking = ({ event }) => {
    const trackingEvent = event.data
    
    return (
      <div className="kit-tracking">
        <div className={classNames("event-bubble", "kit-details")}>
          <img 
            src={ImageMap[trackingEvent.kit?.productType?._id]} 
            className="kit-img"
          />
          <Text>
            <strong>{trackingEvent.kit?.productType?.title}:</strong> {trackingEvent.eventType}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={trackingEvent.kit?.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
          <Bullet />
          <TrackingLink trackingNumber={trackingEvent.kit?.tracking.trackingNumber} />
        </div>
      </div>
    )
  }

  const PrescriptionCreated = ({ event }) => {
    const prescription = event.data
    
    return (
      <div className="prescription-created">
        <div className={classNames("event-bubble", "prescription-details")}>
          {ImageMap[prescription.type] && (
            <img 
              src={ImageMap[prescription.type]} 
              className="prescription-img"
            />
          )}
          <Text>
            <strong>Prescription ordered:</strong> {prescription.type} 
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={prescription.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const PrescriptionPaused = ({ event }) => {
    const prescription = event.data
    
    return (
      <div className="prescription-paused">
        <div className={classNames("event-bubble", "prescription-details")}>
          {ImageMap[prescription.type] && (
            <img 
              src={ImageMap[prescription.type]} 
              className="prescription-img"
            />
          )}
          <Text>
            <strong>Prescription paused:</strong> {prescription.type} 
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={prescription.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const PrescriptionSent = ({ event }) => {
    const prescription = event.data
    
    return (
      <div className="prescription-sent">
        <div className={classNames("event-bubble", "prescription-details")}>
          {ImageMap[prescription.type] && (
            <img 
              src={ImageMap[prescription.type]} 
              className="prescription-img"
            />
          )}
          <Text>
            <strong>Prescription sent to GoGoMeds:</strong> {prescription.type} 
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={prescription.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const PrescriptionTracking = ({ event }) => {
    const trackingEvent = event.data
    
    return (
      <div className="prescription-tracking">
        <div className={classNames("event-bubble", "prescription-details")}>
          {ImageMap[trackingEvent.prescription?.type] && (
            <img 
              src={ImageMap[trackingEvent.prescription.type]} 
              className="prescription-img"
            />
          )}
          <Text>
            <strong>{trackingEvent.prescription?.type}:</strong> {trackingEvent.eventType}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={trackingEvent.prescription?.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
          <Bullet />
          <TrackingLink trackingNumber={trackingEvent.prescription?.tracking.trackingNumber} />
        </div>
      </div>
    )
  }

  const AppointmentScheduled = ({ event }) => {
    const appointment = event.data
    
    return (
      <div className="appointment-scheduled">
        <div className={classNames("event-bubble")}>
          <Text>
            <strong>Blood draw scheduled:</strong> {moment(appointment.start).format('MMM DD, YYYY')}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={appointment.patients[0]} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const AppointmentComplete = ({ event }) => {
    const appointment = event.data
    
    return (
      <div className="appointment-scheduled">
        <div className={classNames("event-bubble")}>
          <Text>
            <strong>Blood draw complete:</strong> {moment(appointment.start).format('MMM DD, YYYY')}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={appointment.patients[0]} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const ConsultScheduled = ({ event }) => {
    const consult = event.data
    
    return (
      <div className="consult-scheduled">
        <div className={classNames("event-bubble")}>
          <Text>
            <strong>Consult scheduled:</strong> {moment(consult.start).format('MMM DD, YYYY')}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={consult.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }

  const ConsultComplete = ({ event }) => {
    const consult = event.data
    
    return (
      <div className="consult-scheduled">
        <div className={classNames("event-bubble")}>
          <Text>
            <strong>Consult complete:</strong> {moment(consult.start).format('MMM DD, YYYY')}
          </Text>
        </div>

        <div className="event-footer">
          <PatientLink patient={consult.patient} />
          <Bullet />
          <EventTimestamp timestamp={event.timestamp} />
        </div>
      </div>
    )
  }


  const EventItem = ({ event }) => {
    switch (event.type) {
      case HeartEventType.NOTE:
        return <Note event={event} />
      case HeartEventType.APPOINTMENT_SCHEDULED:
        return <AppointmentScheduled event={event} />
      case HeartEventType.APPOINTMENT_COMPLETE:
        return <AppointmentComplete event={event} />
      case HeartEventType.KIT_ORDERED:
        return <KitOrdered event={event} />
      case HeartEventType.KIT_TRACKING:
        return <KitTracking event={event} />
      case HeartEventType.CONSULT_SCHEDULED:
        return <ConsultScheduled event={event} />
      case HeartEventType.CONSULT_COMPLETE:
        return <ConsultComplete event={event} />
      case HeartEventType.PRESCRIPTION_CREATED:
        return <PrescriptionCreated event={event} />
      case HeartEventType.PRESCRIPTION_SENT:
        return <PrescriptionSent event={event} />
      case HeartEventType.PRESCRIPTION_PAUSED:
        return <PrescriptionPaused event={event} />
      case HeartEventType.PRESCRIPTION_TRACKING:
        return <PrescriptionTracking event={event} />
      case HeartEventType.RESULT_RETURNED:
        return <ResulReturned event={event} />
      case HeartEventType.REMINDER:
        return <Reminder event={event} />
    }
  }

  const getEventColor = (event) => {
    switch (event.type) {
      case HeartEventType.APPOINTMENT_COMPLETE:
      case HeartEventType.CONSULT_COMPLETE:
        return moment(event.timestamp) >= moment() ? Color.warning : Color.success
      case HeartEventType.REMINDER:
        return event.data.status === ReminderStatus.COMPLETE ? Color.success : moment(event.timestamp) >= moment() ? Color.warning : Color.error
      default:
        return Color.success
    }
  }

  const TimelineHeader = () => {
    return (
      <Affix offsetTop={0}>
        <div className="timeline-header">
          <div className="timeline-header-title">
            Events
            <Text className="timeline-header-count">
              {total.toLocaleString()}
            </Text>
          </div>

          <div className="timeline-filters">
            <Select 
              placeholder="Select event type..."
              options={Object.values(HeartEventType).sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' })).map(eventType => {
                return {
                  label: eventType,
                  value: eventType
                } 
              })}
              value={searchParams.get('eventType')}
              onChange={eventType => {
                const updatedParams = new URLSearchParams(searchParams)
                if (eventType) {
                  updatedParams.set('eventType', eventType)
                } else {
                  updatedParams.delete('eventType')
                }
                setSearchParams(updatedParams)
              }}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="event-type-filter"
              showSearch
              allowClear
            />

            <Select 
              placeholder="Select patient..."
              options={patients?.map(patient => {
                return {
                  label: `${patient.firstName} ${patient.lastName}`,
                  value: patient._id
                } 
              })}
              value={patients?.length ? searchParams.get('patient') : null}
              onChange={patientId => {
                const updatedParams = new URLSearchParams(searchParams)
                if (patientId) {
                  updatedParams.set('patient', patientId) 
                } else {
                  updatedParams.delete('patient')
                }
                setSearchParams(updatedParams)
              }}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="patient-filter"
              showSearch
              allowClear
            />
          </div>
        </div>
      </Affix>
    )
  }

  return (
    <div className="heart-health-events">
      <TimelineHeader />

      <div className="timeline-container">
        <InfiniteScroll
          dataLength={events.length}
          next={fetchMoreEvents}
          hasMore={hasMore}
          loader={<Paragraph className="loading-events"><Spin size="small" />  Loading more events...</Paragraph>}
          scrollableTarget="timeline-container"
        >
          <Timeline
            className="timeline"
            items={events.map(event => {
              return {
                children: (
                  <EventItem 
                    event={event} 
                  />
                ),
                color: getEventColor(event)
              }
            })}
          />
        </InfiniteScroll>
      </div>

      <ReminderForm
        open={openReminderForm}
        setOpen={setOpenReminderForm}
        reminderId={selectedReminderId}
        setReminderId={setSelectedReminderId}
        select="text patient status scheduledAt completeAt"
        populate={[{
          path: 'patient',
          select: 'firstName lastName'
        }, {
          path: 'author',
          select: 'firstName lastName'
        }]}
        onSuccess={reminder => {
          setEvents(events.map(event => {
            if (event.data._id === reminder._id) {
              return {
                ...event,
                data: reminder,
                timestamp: reminder.status === ReminderStatus.COMPLETE ? reminder.completeAt : reminder.scheduledAt
              }
            }
            return event
          }))
          setSelectedReminderId(null)
        }}
      />

      <NoteForm
        open={openNoteForm}
        setOpen={setOpenNoteForm}
        onSuccess={note => {
          setEvents(events.map(event => {
            if (event.data._id === note._id) {
              return {
                ...event,
                data: note
              }
            }
            return event
          }))
          setSelectedNote(null)
        }}
        noteId={selectedNote?._id}
        patientId={selectedNote?.patient._id}
        currentUserId={currentUser?._id}
      />
    </div>
  )
}
import React, { useState, useEffect, useContext } from "react";
import { Table, Tooltip, Spin } from "antd";
import { UserContext } from "../../contexts/user.context";
import TestHelper from "../../helpers/test.helper.js";
import { TestModal } from "../testModal/testModal.component";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import classNames from "classnames";
import { ReadableRiskLevel, RiskLevel, TestCode } from "../../enums/index.enum";
import "./singleResultsTable.scss";
import ReportHelper from "../../helpers/report.helper.js";
import useWidth from "../../hooks/useWidth.hook.js";
import Breakpoint from "../../enums/breakpoint.enum";
import ResultHelper from "../../helpers/result.helper.js";
import { SyncOutlined } from "@ant-design/icons";
import moment from 'moment'
import PatientHelper from "../../helpers/patient.helper.js";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime); 


export const SingleResultsTable = ({ 
  filterValue, 
  blacklist, 
  testorderLookup,
  tests,
  results,
  reports,
  patientId,
  absoluteCategories,
}) => {
  
  const [openModal, setOpenModal] = useState(false);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const { currentUser } = useContext(UserContext);
  const [incomingTests, setIncomingTests] = useState([])
  const width = useWidth()
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchLatestResults();
  }, [patientId, results, reports, currentUser, absoluteCategories, filterValue]);


  const fetchLatestResults = async () => {
    setIsLoading(true);
    try {

      const progressData = ResultHelper.getProgressData(results[0])
      let incompleteTests = progressData?.incompleteTests

      if (incompleteTests?.length > 0) {
        if (moment(results[0]?.collectedAt).add(2.5, 'weeks') > moment()) {
          incompleteTests.push({_id: "HEALTH_SCORE", name: "Longevity Score"})
          setIncomingTests(incompleteTests);
        }
  
      }
      // Mapping latest result for each test
      const latestResultsMap = results.reduce((acc, result) => {
        result.values.forEach((value) => {

          // Fetch the test object by its ID (value.test)
          const test = tests.find((testItem) => testItem._id === value.test);
          if (!test) return;

          const testCode = test.code; // Get the code from the test object
          if (blacklist.includes(testCode)) return;

          // Determine if the result is incomplete
          const isIncomplete = incompleteTests?.some(incompleteTest => incompleteTest._id === test._id);

          if (value.value==="") return
      
          if (test) {
            if (!acc[testCode] || new Date(result.collectedAt) > new Date(acc[testCode].collectedAt)) {

              // Hack to not show BMI to athletes
              const {patient} = results[0]              
              if ([TestCode.BMI, TestCode.WAIST].includes(testCode) && (PatientHelper.isAthlete(patient))) {
                return
              }

              acc[testCode] = {
                testName: test?.name || '',
                goal: getOptimalRange(test) !== undefined && getOptimalRange(test) !== null ? getOptimalRange(test) : '',                
                value: value.value,
                unit: test?.unit || '',
                collectedAt: result.collectedAt,
                risk: value.risk || RiskLevel.UNKNOWN,
                testId: test?._id,
                absoluteCategory: test?.absoluteCategory || null,
                testCode: test?.code,
                isIncomplete: isIncomplete,
                description: test?.tagline
              };
            }
          }
        });
        return acc;
      }, {});

      // Convert to array
      let latestResultsArray = Object.values(latestResultsMap);

      // Apply the filter based on the `filterValue` prop
      latestResultsArray = filterResults(latestResultsArray, filterValue);

      // Combine all results into one table with categories as headers
      const combinedDataSource = absoluteCategories.reduce((acc, category) => {
        const categoryTests = latestResultsArray.filter((result) => result.absoluteCategory === category._id);

        if (categoryTests.length > 0) {

          // push the category header row
          acc.push({
            key: `category-${category._id}`,
            categoryName: category.name,
            isCategory: true, // Use this to mark row as a category header
          });

          // Map the category._id to category.code from the absoluteCategories list
          const categoryCode = absoluteCategories.find(cat => cat._id === category._id)?.code ?? 0;

          // Fetch the order map for the current category based on the category code
          const orderMap = (categoryCode || categoryCode===0) ? (testorderLookup[categoryCode] || {}) : {};

          // Sort the tests based on TESTORDER_LOOKUP
          const orderedTests = categoryTests.sort((a, b) => {
            const orderA = orderMap[a.testCode] !== undefined ? orderMap[a.testCode] : 999;
            const orderB = orderMap[b.testCode] !== undefined ? orderMap[b.testCode] : 999;
            return orderA - orderB;
          });

          // Ensure the test row does not display the category name
          orderedTests.forEach((test) => {
            acc.push({
              ...test,
              categoryName: "", 
            });
          });
        }

        return acc;
      }, []);

      // Insert Longevity Score at the top
      const longevityScore = getLongevityScore(reports);
      if (longevityScore) {
        combinedDataSource.unshift({
          testName: 'Longevity Score',
          value: longevityScore.value,
          unit: longevityScore.unit,
          goal: '≥90', // TO DO: dynamic
          risk: longevityScore.risk,
          collectedAt: longevityScore.collectedAt,
          isLongevityScore: true,
          testId: 'HEALTH_SCORE', 
          isIncomplete: incompleteTests?.some((test) => test._id === 'HEALTH_SCORE')
        });
        combinedDataSource.unshift({
          isCategory: true,
          categoryName: 'Longevity Score',
        });
      }

      setDataSource(combinedDataSource)


    } catch (error) {
      console.error("Error fetching latest results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLongevityScore = (reports) => {
    const longevityReport = reports.find(report => report.healthScore);
    if (longevityReport) {
      return {
        value: longevityReport.healthScore,
        risk: ReportHelper.getHealthScoreRisk(longevityReport),
        collectedAt: longevityReport.result.collectedAt
      };
    }
    return null;
  };

  const filterResults = (results, filterValue) => {
    return results.filter(result => {
      // Apply the risk filtering, including Longevity Score
      switch (filterValue) {
        case "high-risk":
          return result.risk === RiskLevel.HIGH;
        case "high-moderate-risk":
          return result.risk === RiskLevel.HIGH || result.risk === RiskLevel.MODERATE;
        case "low-risk":
          return result.risk === RiskLevel.OPTIMAL;
        default:
          return true; // Show all results if no filter is selected
      }
    });
  };

  const getOptimalRange = (test) => {
    if (test.code === TestCode.APO_E) return "e3/e3, e2/e3";
    const optimalRange = test.risk.find(
      ({ level }) => level === RiskLevel.OPTIMAL
    );
    return TestHelper.formatRange(optimalRange);
  };

  const mergedColumns = [
    {
      title: "Test Name",
      dataIndex: "testName",
      key: "testName",
      fixed: "left",
      ellipsis: true,
      className: "test-name",
      render: (text, record) => {
        if (record.isCategory) {
          return {
            children: <>{record.categoryName}</>,
            props: { colSpan: 1 }, // Span across all columns for category header
          };
        }

        // Check if the test is incomplete
        const isIncomplete = record.isIncomplete

        return (
          <Tooltip title={record.description || ""} placement="topLeft">
            <div className="test-name-container">
              {isIncomplete && (
                  <Tooltip title={"Result incoming"}>
                  <span className="incomplete-indicator"><SyncOutlined/></span> 
                  </Tooltip>
              )}
              {text}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Latest Value",
      dataIndex: "value",
      key: "value",
      align: "center",
      render: (value, record) => {
        if (record.isCategory) {
          return {
            children: <>Latest Value</>,
            props: { colSpan: 1 }, 
          };
        }

        // Check if the test is incomplete
        const isIncomplete = record.isIncomplete
        return (
          <Tooltip title={isIncomplete ? "Result incoming" : ReadableRiskLevel[record.risk]}>
             <div className={classNames("risk-tag", `${record.risk}-risk`)}>{isIncomplete &&  <span className="incomplete-indicator"><SyncOutlined/>{" "}</span>}{value}</div>
          </Tooltip>
        )
        
      }


          

        // ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      fixed: "right",
      className: "test-unit",
      width: width >= Breakpoint.LG ? null : 100,
      render: (text, record) => (record.isCategory ? { children: <>Unit</>, props: { colSpan: 1 } } : text),
    },
  ];

  // Conditionally add the "Goal" column if the width is greater than the breakpoint
  if (width >= Breakpoint.MD) {
    mergedColumns.splice(1, 0, {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      render: (text, record) => (record.isCategory ? { children: <>Goal</>, props: { colSpan: 1 } } : text),
    });
    mergedColumns.push({
      title: "Test Date",
      dataIndex: "collectedAt",
      key: "collectedAt",
      render: (collectedAt, record) =>
        record.isCategory
          ? { children: <>Test Date</>, props: { colSpan: 1 } }
          : (
            <Tooltip title={dayjs(collectedAt).fromNow()}>
              {dayjs(collectedAt).format("MM/DD/YY")}
            </Tooltip>
          ),
    });
  }


  return (
    <div className="single-results-table">
      {isLoading ? (
        <div className="loading">
          <div className="loading-text">
            <Spin /> &nbsp;&nbsp;Loading
          </div>
        </div>
      ) : (
        <>
          <TestModal
            open={openModal}
            setOpen={setOpenModal}
            testId={selectedTestId}
            setTestId={setSelectedTestId}
            tests={tests}
            results={results}
            reports={reports}
          />

          {/* Display the number of incoming tests below the "Latest Results" header */}
          {incomingTests.length > 0 && (
              <div className="incoming-tests-message">
              <Tooltip title={
                  <>
                  {incomingTests?.map(test => (
                    <li
                      key={`incomplete-test-${test._id}`}
                      className="incomplete-test-name"
                    >
                      - {test.name}
                    </li>
                  ))}
                  </>              
                }>
                <SyncOutlined /> {incomingTests.length} test result{incomingTests.length > 1 ? 's' : ''} still pending
                </Tooltip>
              </div>
            
          )}

          <Table          
            size="small"
            dataSource={dataSource}
            showHeader={false}
            columns={mergedColumns}
            rowKey={(record) => record.key || record.testId}
            pagination={false}
            scroll={{ x: true }}
            onRow={(record) => ({
              onClick: () => {
                if (!record.isCategory) {
                  setSelectedTestId(record.testId);
                  setOpenModal(true);
                }
                else if (record.isLongevityScore) {
                  setSelectedTestId("longevity-score");
                  setOpenModal(true);
                }
              },
            })}
            rowClassName={(record) => (record.isCategory ? "is-category" : "")} // Add is-category class for category rows
            className="primary-table"
          />
        </>
      )}
    </div>
  );
};

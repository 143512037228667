
import React, { useMemo, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { Appointments } from "./components/appointments/appointments.component";
import { Logout } from "./components/logout/logout.component";
import { Login } from "./components/login/login.component";
import { Privacy } from "./components/privacy/privacy.component";
import { Terms } from "./components/terms/terms.component";
import { Results } from "./components/results/results.component";
import { Auth } from "./components/auth/auth.component";

// ADMIN
import { Admins } from "./components/admins/admins.component";
import { AdminAppointments } from "./components/adminAppointments/adminAppointments.component";
import { AdminResults } from "./components/adminResults/adminResults.component";
import { Providers } from "./components/providers/providers.component";
import { MasterCalendar } from "./components/masterCalendar/masterCalendar.component";
import { Patients } from "./components/patients/patients.component";
import { Phlebotomists } from "./components/phlebotomists/phlebotomists.component";
import { AdminStaffs } from "./components/adminStaffs/adminStaffs.component";

import { MedicalInfo } from "./components/medicalInfo/medicalInfo.component";
import { Invoices } from "./components/invoices/invoices.component";
import { AdminInvoices } from "./components/adminInvoices/adminInvoices.component";
import { UniversalReport } from "./components/universalReport/universalReport.component";
import { Tests } from "./components/tests/tests.component";
import { ResetPassword } from "./components/resetPassword/resetPassword.component";
import { Impersonate } from "./components/impersonate/impersonate.component";
import { AdminReports } from "./components/adminReports/adminReports.component";
import { Join } from "./components/join/join.component";
import { JoinEvent } from "./components/joinEvent/joinEvent.component";
import ScrollToTop from "./scrollToTop";
import { AdminReferrals } from "./components/adminReferrals/adminReferrals.component";
import { AdminNotes } from "./components/adminNotes/adminNotes.component"
import { Consults } from "./components/consults/consults.component";
import { AdminConsults } from "./components/adminConsults/adminConsults.component";
import { AdminLabs } from "./components/adminLabs/adminLabs.component"
import DefaultLayout from "./components/layouts/default.layout";
import ProLayout from "./components/layouts/pro.layout";
import AdminLayout from "./components/layouts/admin.layout";
import PatientLayout from "./components/layouts/patient.layout";
import ProviderLayout from "./components/layouts/provider.layout";
import StaffLayout from "./components/layouts/staff.layout";
import PhlebotomistLayout from "./components/layouts/phlebotomist.layout";
import { Blogs } from "./components/blogs/blogs.component";
import { BlogView } from "./components/blogView/blogView.component";
import { Authors } from "./components/authors/authors.component";
import BiomarkerPanel from "./components/biomarkerPanel/biomarkerPanel.component";
import { Newsletter } from "./components/newsletter/newsletter.component";
import { Interests } from "./components/interests/interests.component";
import { Gift } from "./components/gift/gift.component"
import { BlogForm } from "./components/blogForm/blogForm.component";
import { AdminOrders } from "./components/adminOrders/adminOrders.component";
import { Memberships } from "./components/memberships/memberships.component";
import { AdminMemberships } from "./components/adminMemberships/adminMemberships.component";
import { Feedback } from "./components/feedback/feedback.component";
import { Testing } from "./components/testing/testing.component";
import { AdminContents } from "./components/adminContents/adminContents.component";
import { Galleri } from "./components/galleri/galleri.component";
import { AdminPrescriptions } from "./components/adminPrescriptions/adminPrescriptions.component";
import FlowSplit from "./components/flowSplit/flowSplit.component";
import { Prescriptions } from "./components/prescriptions/prescriptions.component";
import { AdminProducts } from "./components/adminProducts/adminProducts.component";
import { Dashboard } from "./components/dashboard/dashboard.component";
import { Gameplan } from "./components/gameplan/gameplan.component";
import { Home } from "./components/home/home.component";
import { Testkits } from "./components/testkits/testkits.component";
import { Files } from "./components/files/files.component";
import { AdminFacilities } from "./components/adminFacilities/adminFacilities.component";
import FullStory from 'react-fullstory';
import { AdminTasks } from "./components/adminTasks/adminTasks.component";
import { Profile } from "./components/profile/profile.component";
import { Athlete } from "./components/athlete/athlete.component";
import { Members } from "./components/members/members.component";
import { AdminAppointmentParents } from "./components/adminAppointmentParents/adminAppointmentParents.component.js";
import { ViewPatient } from "./components/viewPatient/viewPatient.component.js";
import { Billing } from "./components/billing/billing.component.js";
import { ProductTypes } from "./components/productTypes/productTypes.component.js";
import { MembershipTypes } from "./components/membershipTypes/membershipTypes.component.js";
import { ViewReport } from "./components/viewReport/viewReport.component.js";
import AddOnTests from "./components/addOnTests/addOnTests.component.js";
import { Pro } from "./components/marketing/pro/pro.component.js";
import { ProInvite } from "./components/proInvite/proInvite.component.js";
import { Cleerly } from "./components/cleerly/cleerly.component.js";
import { Referrals } from "./components/referrals/referrals.component.js";
import { v4 as uuidv4 } from 'uuid';
import Role from "./enums/role.enum.js";
import { useEffect } from "react";
import { buildUser, getMe, listUserCounts } from "./services/user.service.js";
import StorageKey from "./enums/storageKey.enum.js";
import { FlowSample } from "./components/flowSample/flowSample.component.js";
import { UserContext } from "./contexts/user.context.js";
import MembershipHelper from "./helpers/membership.helper.js";
import { PaymentLink } from "./components/paymentLink/paymentLink.component.js";
import { PaymentLinkRegister } from "./components/paymentLinkRegister/paymentLinkRegister.component.js";
import { PaymentLinkLogin } from "./components/paymentLinkLogin/paymentLinkLogin.component.js";
import { FertilityTest } from "./components/fertilityTest/fertilityTest.component.js";
import { HeartHealthTest } from "./components/heartHealthTest/heartHealthTest.component.js";
import { Cac } from "./components/cac/cac.component.js";
import { TestosteronePanel } from "./components/testosteronePanel/testosteronePanel.component.js";
import { CholesterolTreatmentTest } from "./components/cholesterolTreatmentTest/cholesterolTreatmentTest.component.js";
import { Biobeat } from "./components/biobeat/biobeat.component.js";
import { Truage } from "./components/truage/truage.component.js";
import { Heart } from "./components/heart/heart.component.js";
import { HHMembers } from "./components/hhMembers/hhMembers.component.js";
import { Support } from "./components/support/support.component.js";
import { AdminHeartHealth } from "./components/admiHeartHealth/adminHeartHealth.component.js";
import { AcceptInvite } from "./components/acceptInvite/acceptInvite.component";
import { HeartHealthEvents } from "./components/heartHealthEvents/heartHealthEvents.component.js";

function App() {
  const [token, setToken] = useState(localStorage.getItem(StorageKey.TOKEN))
  // TODO: patientTracker is obsolete; remove after a year has passed [07/29/24]
  const [tracker, setTracker] = useState(localStorage.getItem(StorageKey.TRACKER) || localStorage.getItem('patientTracker'))
  const [currentUser, setCurrentUser] = useState()
  const [instalabMembership, setInstalabMembership] = useState()
  const [counts, setCounts] = useState({})
  const [memberships, setMemberships] = useState()

  useEffect(() => {
    fetchTracker()
    fetchCounts()
  }, [token])

  useEffect(() => {
    fetchCurrentUserData()
  }, [token, tracker])


  const fetchTracker = async () => {
    if (!tracker && !token) {
      const code = uuidv4()
      localStorage.setItem(StorageKey.TRACKER, code)
      await buildUser({
        fields: {
          tracker: code
        },
        select: '_id',
        populate: [],
      })
      setTracker(code)
    }
  }

  const fetchCurrentUserData = async () => {
    if (token || tracker) {
      const response = await getMe({
        select: '_id role credits email firstName lastName tracker location isShareable isAthlete permissions chargePersonalCard',
        populate: [{
          path: 'memberships',
          select: 'status startAt endAt',
          populate: {
            path: 'membershipType',
            select: 'code'
          }
        }]
      })
      if (response) {
        setMemberships(response.memberships)
        setInstalabMembership(MembershipHelper.getActiveInstalabMembership(response.memberships))
        setCurrentUser(response)
      }
    } else {
      setCurrentUser(null)
      setInstalabMembership(null)
      setMemberships(null)
    }
  }

  const fetchCounts = async () => {
    if (token) {
      setCounts(await listUserCounts())
    } else {
      setCounts({})
    }
  }

  const userValue = useMemo(() => ({
    currentUser,
    setCurrentUser,
    instalabMembership,
    setInstalabMembership,
    token,
    setToken,
    counts,
    setCounts,
    tracker,
    setTracker,
    memberships,
    setMemberships
  }),
  [
    currentUser,
    setCurrentUser,
    instalabMembership,
    setInstalabMembership,    
    token,
    setToken,
    counts,
    setCounts,
    tracker,
    setTracker,
    memberships,
    setMemberships
  ])

  return (
    <div className="App">
      {!token && <FullStory org={'18SRX2'} />}

      <UserContext.Provider value={userValue}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route element={<ProLayout />}>
              <Route path="/pro" element={<Pro />}></Route>
              <Route path="/pro-invite/:id" element={<ProInvite />}></Route>
              <Route path="/provider-flow/:flowType" element={<FlowSplit />} />
              <Route path="/provider-flow/:flowType/:code" element={<FlowSplit />} />
              <Route path="/provider-flow/:flowType/:code/:flowId" element={<FlowSplit />} />
              <Route path="/pro-flow/:flowType" element={<FlowSplit />} />
              <Route path="/pro-flow/:flowType/:code" element={<FlowSplit />} />
              <Route path="/pro-flow/:flowType/:code/:flowId" element={<FlowSplit />} />
            </Route>

            <Route>
              <Route path="/admin/hh" element={<Auth><AdminHeartHealth /></Auth>} />
              <Route path="/admin/hh/events" element={<Auth><HeartHealthEvents /></Auth>} />
            </Route>

            <Route element={<DefaultLayout />}>
              <Route path="/payment/:id" element={<PaymentLink />} />
              <Route path="/payment/:id/register" element={<PaymentLinkRegister />} />
              <Route path="/payment/:id/login" element={<PaymentLinkLogin />} />

              <Route path="/flow-sample" element={<FlowSample />} />
            
              {/* Auth protected flows */}
              <Route path="/flow/:flowType" element={<FlowSplit />} />
              <Route path="/flow/:flowType/:code" element={<FlowSplit />} />
              <Route path="/flow/:flowType/:code/:flowId" element={<FlowSplit />} />

              <Route path="/login" element={<Login />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/reset/:id" element={<ResetPassword />} />

              <Route path="/newsletter/:slug" element={<BlogView />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/panel" element={<BiomarkerPanel />} />
              <Route path="/gift" element={<Gift/>}/>
              <Route path="/feedback" element={<Feedback/>}/>
              <Route path="/cholesterol" element={<Heart/>}/>
              <Route path="/athlete" element={<Athlete/>}/>
              <Route path="/biobeat" element={<Biobeat/>}/>
              <Route path="/addons" element={<AddOnTests/>}></Route>
              <Route path="/accept/:id" element={<AcceptInvite />} />

              {/* TESTS */}
              <Route path="/testing" element={<Testing/>}/>
              <Route path="/longevity-panel" element={<Testing/>}/>
              <Route path="/galleri-test" element={<Galleri/>}/>
              <Route path="/cleerly" element={<Cleerly/>}/>
              <Route path="/fertility" element={<FertilityTest/>}/>
              <Route path="/heart-health-test" element={<HeartHealthTest/>}/>
              <Route path="/heart" element={<Heart />}/>
              <Route path="/cac" element={<Cac/>}/>
              <Route path="/testosterone" element={<TestosteronePanel/>}/>
              <Route path="/cholesterol-treatment-test" element={<CholesterolTreatmentTest/>}/>
              <Route path="/truage" element={<Truage/>}/>

              <Route path="/logout" element={<Logout />} />
              <Route path="/appointments/:appointmentId/join" element={<Join />} />
              <Route path="/events/:appointmentParentId/join" element={<JoinEvent />} />
              {!token && <Route path="/" element={<Home />} />}
            </Route>

            {currentUser?.role === Role.ADMIN && 
              <Route element={<Auth><AdminLayout /></Auth>}>
                <Route path="/billing" element={<Billing />} />
                <Route path="/pro-flow/:flowType" element={<FlowSplit />} />
                <Route path="/pro-flow/:flowType/:code" element={<FlowSplit />} />
                <Route path="/pro-flow/:flowType/:code/:flowId" element={<FlowSplit />} />
                <Route path="/admin/memberships" element={<AdminMemberships />} />
                <Route path="/admin/contents" element={<AdminContents />} />
                <Route path="/admin/product-types" element={<ProductTypes />} />
                <Route path="/admin/membership-types" element={<MembershipTypes />} />
                <Route path="/admin/blogs" element={<Blogs />} />
                <Route path="/admin/blogs/:blogId" element={<BlogForm />} />
                <Route path="/admin/blogs/new" element={<BlogForm />} />
                <Route path="/admin/calendar" element={<MasterCalendar />} />
                <Route path="/admin/referrals" element={<AdminReferrals />} />
                <Route path="/admin/products" element={<AdminProducts />} />
                <Route path="/admin/prescriptions" element={<AdminPrescriptions />} />
                <Route path="/admin/appointments" element={<AdminAppointments />} />
                <Route path="/admin/authors" element={<Authors />} />
                <Route path="/admin/consults" element={<AdminConsults />} />
                <Route path="/admin/results" element={<AdminResults />} />

                <Route path="/admin/patients" element={<Patients />} />
                <Route path="/admin/members" element={<Members/>} />
                <Route path="/admin/hhmembers" element={<HHMembers/>} />
                <Route path="/admin/staffs" element={<AdminStaffs/>} />
                <Route path="/admin/phlebotomists" element={<Phlebotomists />} />
                <Route path="/admin/providers" element={<Providers />} />

                <Route path="/admin/interests" element={<Interests />} />
                <Route path="/admin/reports" element={<AdminReports />} />
                <Route path="/admin/notes" element={<AdminNotes />} />
                <Route path="/admin/labs" element={<AdminLabs />} />
                <Route path="/admin/orders" element={<AdminOrders />} />
                <Route path="/admin/files" element={<Files />} />
                <Route path="/admin/facilities" element={<AdminFacilities/>} />
                <Route path="/admin/tasks" element={<AdminTasks />} />
                <Route path="/admin/events" element={<AdminAppointmentParents/>} />
                
                <Route path="/patients/:patientId" element={<ViewPatient />} />

                <Route path="/admin/payments" element={<AdminInvoices />} />
                <Route path="/admin/tests" element={<Tests />} />
                <Route path="/admin/admins" element={<Admins />} />
                <Route path="/patients/:patientId/reports/:reportId" element={<ViewReport />} />
                <Route path="/" element={<Patients />} />
              </Route>
            }

            {currentUser?.role === Role.PROVIDER &&
              <Route element={<Auth><ProviderLayout /></Auth>}>
                <Route path="/billing" element={<Billing />} />
                <Route path="/provider/consults" element={<AdminConsults />} />
                <Route path="/provider/patients" element={<Patients />} />
                <Route path="/provider/appointments" element={<AdminAppointments />} />
                <Route path="/provider/consults" element={<AdminConsults />} />
                <Route path="/provider/results" element={<AdminResults />} />
                <Route path="/provider/reports" element={<AdminReports />} />
                <Route path="/provider/prescriptions" element={<AdminPrescriptions />} />
                <Route path="/provider/referrals" element={<AdminReferrals />} />

                <Route path="/patients/:patientId" element={<ViewPatient />} />
                <Route path="/patients/:patientId/reports/:reportId" element={<ViewReport />} />
                <Route path="/" element={<Patients />} />
              </Route>
            }

            {currentUser?.role === Role.STAFF && 
              <Route element={<Auth><StaffLayout /></Auth>}>
                <Route path="/staff/calendar" element={<MasterCalendar />} />
                <Route path="/staff/orders" element={<AdminOrders />} />
                <Route path="/staff/phlebotomists" element={<Phlebotomists />} />
                <Route path="/staff/tasks" element={<AdminTasks/>}/>
                <Route path="/staff/facilities" element={<AdminFacilities/>}/>
                <Route path="/staff/referrals" element={<AdminReferrals />} />
                <Route path="/staff/providers" element={<Providers />} />

                <Route path="/patients/:patientId" element={<ViewPatient />} />

                <Route path="/" element={<AdminOrders />} />
                
              </Route>
            }

            {currentUser?.role === Role.PHLEBOTOMIST && 
              <Route element={<Auth><PhlebotomistLayout /></Auth>}>
                <Route path="/phlebotomist/tasks" element={<AdminTasks />} />
                <Route path="/" element={<AdminTasks />} />
              </Route>
            }
            
            {currentUser?.role === Role.PATIENT && (
              <Route element={<Auth><PatientLayout /></Auth>}>
                <Route path="/patient/results" element={<Results />} />

                <Route path="/patient/payments" element={<Invoices />} />
                <Route path="/patient/appointments" element={<Appointments />} />
                <Route path="/patient/kits" element={<Testkits />} />
                <Route path="/patient/consults" element={<Consults />} />
                <Route path="/patient/memberships" element={<Memberships />} />
                <Route path="/patient/payments" element={<Invoices />} />
                <Route path="/patient/medical" element={<MedicalInfo />} />
                <Route path="/patient/report" element={<UniversalReport />} />
                <Route path="/patient/treatments" element={<Prescriptions />} />
                <Route path="/patient/home" element={<Dashboard/>} />
                <Route path="/patient/plan" element={<Gameplan/>} />
                <Route path="/patient/files" element={<Files/>} />
                <Route path="/patient/profile" element={<Profile/>} />
                {/* <Route path="/patient/referrals" element={<Referrals/>}/> */}
                <Route path="/billing" element={<Billing/>} />
                <Route path="/help" element={<Support/>}/>
                <Route path="/" element={<Dashboard/>} />
              </Route>
            )}

            <Route path="/impersonate/:id" element={<Impersonate />} />
          </Routes>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;

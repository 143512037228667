import React, { useContext, useEffect, useState } from 'react';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { EditOutlined, EnvironmentOutlined, DeleteOutlined } from '@ant-design/icons';
import { Modal, Form, message, Input, Button, Select, Popconfirm } from 'antd';
import { listReferrals, updateReferral, addReferral, getReferral, removeReferral } from "../../services/referral.service.js";
import './referrals.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { ContinueButton } from '../continueButton/continueButton.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import { ReferralType, Role, ObjectType } from "../../enums/index.enum.js";

const { Item } = Form;
const select = '_id firstName lastName location title scheduleLink type author';

const ReferralForm = ({ open, setOpen, referralId, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open && referralId) {
      setReferralFields();
    } else {
      form.resetFields();
    }
  }, [open, referralId]);

  const setReferralFields = async () => {
    const { 
      firstName,
      lastName,
      title,
      scheduleLink,
      type
     } = await getReferral(referralId, { select });
    form.setFieldsValue({ 
      firstName, 
      lastName, 
      title,
      scheduleLink,
      type  
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const { 
        firstName,
        lastName,
        title,
        scheduleLink,
        type 
      } = form.getFieldsValue();
      const params = { 
        fields: {
          firstName,
          lastName,
          title,
          scheduleLink,
          type
        },
        select
      };
      const response = referralId ? await updateReferral(referralId, params) : await addReferral(params);
      if (onSuccess) {
        onSuccess(response);
      }
      message.success(referralId ? 'Referral updated' : 'Referral added');
      setOpen(false);
      form.resetFields(); // Resetting form fields in both cases
    } catch (err) {
      let msg = referralId ? 'Failed to update Referral' : 'Failed to add Referral';
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email';
      }
      message.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      open={open}
      title={referralId ? 'Edit Referral' : 'Add Referral'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          key="submit"
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="admin-referrals-form"
      >

        <Item
          name="type"
          label="Referral Type"
          rules={[{
            required: true,
            message: 'Select referral type'
          }]}
        >
          <Select
            placeholder="Select referral type"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(ReferralType).sort().map(referralType => {
              return {
                label: referralType,
                value: referralType,
              }
            })}
          />
        </Item>
        <Item
          label="Title"
          name="title"
        >
          <Input placeholder="Title, e.g. Dr." />
        </Item>
        <Item
          label="First Name"
          name="firstName"
          rules={[{
            required: true,
            message: 'Enter first name',
          }]}
        >
          <Input placeholder="First Name" />
        </Item>

        <Item
          label="Last Name"
          name="lastName"
          rules={[{
            required: true,
            message: 'Enter last name',
          }]}
        >
          <Input placeholder="Last Name" />
        </Item>

        <Item
          label="Schedule Link"
          name="scheduleLink"
          rules={[{
            required: true,
            message: 'Enter schedule link',
          }]}
        >
          <Input placeholder="Schedule link, e.g. calendly" />
        </Item>
      </Form>
    </Modal>
  );
};

export const Referrals = () => {
  const [referrals, setReferrals] = useState([]);
  const [referralId, setreferralId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Explicitly set to false
  const [isAdminLocationModalOpen, setIsAdminLocationModalOpen] = useState();
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts, currentUser } = useContext(UserContext); // Get the user object from the context

  useEffect(() => {
    fetchReferrals();
  }, []);

  const fetchReferrals = async () => {
    setIsLoading(true);

    let params = {
      select,
      filter: {},
      populate: [],
      sort: 'firstName'
    };

    const fetchedReferrals = await listReferrals(params);
    setReferrals(fetchedReferrals);
    setFilteredCount(fetchedReferrals.length);
    setIsLoading(false);
  };

  const stripLocation = location => {
    return location.replace(/[^a-zA-Z0-9]/g, '');
  };

  const handleRemoveReferral = async (id) => {
    try {
      await removeReferral(id);
      setReferrals(referrals.filter(referral => referral._id !== id));
      setFilteredCount(cachedFilterCount => cachedFilterCount - 1);
      setCounts(cachedCounts => ({
        ...cachedCounts,
        Referrals: cachedCounts.Referrals - 1
      }));
      message.success('Referral removed');
    } catch (error) {
      message.error('Failed to remove referral');
    }
  };

  const getActionItems = ({ _id }) => [
    {
      key: '1',
      label: (
        <Button onClick={() => {
          setreferralId(_id);
          setIsEditModalOpen(true);
        }}>
          <EditOutlined /> Edit
        </Button>
      )
    },
    // {
    //   key: '2',
    //   label: (
    //     <Button onClick={() => {
    //       setreferralId(_id);
    //       setIsAdminLocationModalOpen(true);
    //     }}>
    //       <EnvironmentOutlined /> Set Location
    //     </Button>
    //   )
    // },
    ([Role.ADMIN, Role.PROVIDER].includes(currentUser?.role)) && {
      key: '3',
      label: (
        <Popconfirm
          title="Are you sure to delete this referral?"
          onConfirm={() => handleRemoveReferral(_id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>
            <DeleteOutlined /> Delete
          </Button>
        </Popconfirm>
      )
    }
  ];

  const getCustomFilter = (Referral, value) => {
    const searchValue = value.replace(/\s/g, '').toLowerCase();
    const firstName = Referral.firstName ? Referral.firstName.toString().toLowerCase() : '';
    const lastName = Referral.lastName ? Referral.lastName.toString().toLowerCase() : '';
    const fullName = (firstName + lastName).replace(/\s/g, '');
    
    return {
      name: () => fullName.includes(searchValue),
    };
  };

  const formatLocation = location => {
    return `${location.streetAddress}${location.streetAddress2 ? ' ' + location.streetAddress2 : ''}, ${location.city}, ${location.state} ${location.postalCode}`;
  };

  // Determine columns to display based on user role
  const columns = [
    {
      title: 'Provider',
      dataIndex: 'firstName',
      filterDropdownType: FilterDropdownType.INPUT,
      render: (firstName, { title, lastName }) => (
        <>{title && <>{title} </>}{firstName} {lastName}</>
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Schedule Link',
      dataIndex: 'scheduleLink',
      render: scheduleLink => scheduleLink && <a href={scheduleLink} target="_blank" rel="noopener noreferrer">Schedule</a>
    }
  ];

  if (currentUser?.role === Role.ADMIN) {
    columns.push({
      title: "Referred By",
      dataIndex: 'author',
      render: author => author ? <>{author.firstName} {author.lastName}</> : 'N/A'
    });
  }

  return (
    <div className="admin-referrals">
      <PageHeader
        title='Referrals'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setreferralId(null);
              setIsEditModalOpen(true);
            }}
          >
            + Add New Referral
          </Button>
        )}
      />

      <AdminLocationModal
        objectId={referralId}
        objectType={ObjectType.Referral}
        open={isAdminLocationModalOpen}
        setOpen={setIsAdminLocationModalOpen}
        select={select}
        onSuccess={referral => {
          setReferrals(referrals.map(p => p._id === referralId ? referral : p));
        }}
      />

      <ReferralForm
        referralId={referralId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={referral => {
          if (referralId) {
            setReferrals(referrals.map(p => p._id === referralId ? referral : p));
          } else {
            setReferrals([referral, ...referrals]);
            setFilteredCount(cachedFilterCount => cachedFilterCount + 1);
            setCounts(cachedCounts => ({
              ...cachedCounts,
              Referrals: cachedCounts.Referrals + 1
            }));
          }
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={referrals}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={columns}
      />
    </div>
  );
};

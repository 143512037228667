import { useState, useEffect, useRef, useContext } from "react"
import DISCOUNT from "../../enums/discount.enum"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useParams } from 'react-router-dom'
import { listMembershipTypes } from "../../services/membershipType.service"
import Color from '../../colors.scss';
import MedicalCondition from "../../enums/medicalCondition.enum"
import Ethnicity from "../../enums/ethnicity.enum"
import { UserContext } from "../../contexts/user.context"
import { PopupModal } from "react-calendly";
import "./heartHealthUpgrade.scss";
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { getDiscountByCode } from "../../services/discount.service"

export const Step = {
  PAY: 'pay',
  NAME: 'name',
  ETHNICITY: 'ethnicity',
  FAMILY_HISTORY: 'family-history',
  MEDICAL_CONDITIONS: 'medical-conditions',
  BP_MONITOR: 'bp',
  SHIPPING: 'shipping',
  CONSULT: 'consult',
  SCHEDULE: 'schedule',
  CONFIRM: 'confirm'
}

const heartHealthConditions = [
  MedicalCondition.HIGH_BLOOD_PRESSURE,
  MedicalCondition.TYPE_1_DIABETES,
  MedicalCondition.TYPE_2_DIABETES,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.STROKE,
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HIGH_CHOLESTEROL,
]

export const HeartHealthUpgradeFlow = () => {
  const { flowId } = useParams()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [hasPass, setHasPass] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const { token, currentUser, instalabMembership } = useContext(UserContext)
  const [open, setOpen] = useState()
  const [discount, setDiscount] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [initialUser, hasPass, membershipTypes, flow, discount, instalabMembership])

  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    resetFlowId()
  }, [flowId])

  useEffect(() => {
    fetchDiscount()
  }, [flowId, token])

  const fetchDiscount = async () => {
    if (!token || !flowId) return
    const fetchedDiscount = await getDiscountByCode(MembershipTypeCode.HEART_HEALTH)
    setDiscount(fetchedDiscount)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.HEART_HEALTH,
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const resetFlowId = () => {
    if (!flowId) return 
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return
    
    setSkeleton({
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONSULT,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.HEART_HEALTH,
        title: discount ? 
          <>Join the Heart Health Program for {getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost - discount.amount === 0 ? <><span className="true-price">a free introductory month</span></> : <>an introductory rate of <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost - discount.amount} for your first month</span></>}. Following this period, you will be charged <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost}/month</span>.</> :
          instalabMembership ? 
          <>Upgrade to the Heart Health Program for <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost - getMembershipType(MembershipTypeCode.LONGEVITY)?.cost} for the first month</span>, then <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost}/month</span>.</> :
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.HEART_HEALTH)?.cost}/month</span> for the Heart Health Program.</>,
        description: <>{instalabMembership ? <>You'll no longer be charged for the Core Membership, as all its benefits are included in the Heart Health Program.<br/><br/></> : ''}In the next step, you will be paired with your Instalab physician and schedule your first Heart Health consultation.</>
      },
      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.SHIPPING,
        addConsult: true,
        title: `Schedule your first Heart Health consultation.`,
        description: `You will have a 30-minute video call with Dr. Steven Winiarski, a specialist lipidology, metabolic health and longevity medicine. During this session, he will collaborate with you to develop an initial strategy for your health plan, establish specific goals, and address any questions you may have.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/3r9-gjk-xjv',
      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.ETHNICITY,
        title: `What is your shipping address?`,
        description: `You will receive your blood test kit, blood pressure monitor, and medication here.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.ETHNICITY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.FAMILY_HISTORY,
        buildUser: true,
        title: `What is your ethnicity?`,
        description: 'Select all that apply.',
        field: {
          name: 'ethnicity',
          options: Object.values(Ethnicity).map(value => ({label: value, value: value}))
        },
        skipIf: () => {
          return initialUser?.ethnicity !== undefined
        }
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.MEDICAL_CONDITIONS,
        buildUser: true,
        title: <>Which medical conditions has your immediately family been diagnosed with <u>before age 60</u>?</>,
        description: 'This includes any grandparents, parents, or siblings. Select all that apply.',
        field: {
          name: 'familyHistory',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
        skipIf: () => {
          return initialUser?.familyHistory !== undefined
        }
      },
      [Step.MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.BP_MONITOR,
        buildUser: true,
        title: <>Which medical conditions have you been diagnosed with?</>,
        field: {
          name: 'heartHealthConditions',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
        skipIf: () => {
          return initialUser?.heartHealthConditions !== undefined
        }
      },
      [Step.BP_MONITOR]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `Do you have a blood pressure monitor?`,
        field: {
          name: 'hasBloodPressureMonitor',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        skipIf: () => {
          return initialUser?.hasBloodPressureMonitor !== undefined
        }
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Congratulations, you're in!`,
        description: <>
          Dr. Steven Winiarski will review your medical history ahead of your upcoming consultation. If additional medical information is needed, our team will contact you with follow-up questions to ensure a more effective consultation.
          <br/><br/>
          If you have any questions, please <a onClick={onChat}>send us a message.</a>
        </>,
        content: null,
        buttonText: 'Back to Dashboard',
        path: `/`,
      }
    })
  }

  return <>
    <div className="heart-health-intake">
      <PopupModal
        open={open}
        url="https://calendly.com/d/cp8q-hch-fvg/heart-health-intro-call"
        onModalClose={() => setOpen(false)}
        rootElement={document.getElementById("root")}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: Color.success,
        }}
        prefill={{
          firstName: currentUser?.firstName ? currentUser.firstName : '',
          lastName: currentUser?.lastName ? currentUser.lastName : '',
          name: currentUser?.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : '',
          email: currentUser?.email ? currentUser.email : '',
        }}
      />

      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        startIndex={1}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    </div>
  </>
}
import { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import { Row, Col, Typography, Button } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns'; // Import date-fns adapter for time scale
import moment from 'moment'
import './heartTrendLineExpanded.scss';
import HeartEventType from '../../enums/heartEventType.enum';
import Color from '../../colors.scss';
import ImageMap from '../../enums/imageMap.enum';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale, annotationPlugin);

const startColor = Color.success
const startColorBackground = '#EAF5F1'
const stopColor = Color.error
const stopColorBackground = '#F8E5E7'

const { Text } = Typography

const isNumber = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const HeartTrendLineExpanded = ({ 
  chartId, 
  trend, 
  prescriptionEvents,
  heartColor,
  metabolismColor,
  liverColor,
  bpColor,
  patient
}) => {
  const [showHeart, setShowHeart] = useState(true)
  const [showMetabolism, setShowMetabolism] = useState(true)
  const [showLiver, setShowLiver] = useState(true)
  const [showBp, setShowBp] = useState(true)
  const [options, setOptions] = useState()
  const currentDate = moment().subtract(5, 'seconds')
  const [startDate, setStartDate] = useState(moment().subtract(345, 'days').toDate());
  const [endDate, setEndDate] = useState(moment().add(20, 'days').toDate());
  const [hoveredPrescriptionEvent, setHoveredPrescriptionEvent] = useState(null); // Track the hovered prescription

  const chartData = convertToChartData(trend);

  const handlePrevious = () => {
    setStartDate(moment(startDate).subtract(365, 'days').toDate());
    setEndDate(moment(endDate).subtract(365, 'days').toDate());
  };

  const handleNext = () => {
    setStartDate(moment(startDate).add(365, 'days').toDate());
    setEndDate(moment(endDate).add(365, 'days').toDate());
  };

  const yPrescriptionSeparation = 65

  const getAnnotationLine = (timestamp, eventType) => {
    return {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x',
      value: new Date(timestamp),
      borderColor: eventType === HeartEventType.PRESCRIPTION_CREATED ? startColor : stopColor,
      borderDash: [3,3],
      borderWidth: 1,
    }
  }

  useEffect(() => {
    if (!prescriptionEvents?.length) return

    const prescriptionPlugin = {
      id: chartId,
      beforeDatasetsDraw: (chart) => {
        if (chart.canvas.id !== chartId) return;
  
        const annotations = prescriptionEvents.map(({ type, timestamp }) => {
          return getAnnotationLine(timestamp, type)
        })
  
        annotations.forEach((annotation) => {
          chart.ctx.save();
          const xPosition = chart.scales.x.getPixelForValue(annotation.value);
          chart.ctx.beginPath();
          chart.ctx.setLineDash(annotation.borderDash);
          chart.ctx.moveTo(xPosition, chart.chartArea.top);
          chart.ctx.lineTo(xPosition, chart.chartArea.bottom);
          chart.ctx.strokeStyle = annotation.borderColor;
          chart.ctx.lineWidth = annotation.borderWidth;
          chart.ctx.stroke();
          chart.ctx.restore();
        });
      },
      afterDatasetDraw: (chart) => {
        if (chart.canvas.id !== chartId) return;
  
        const ctx = chart.ctx;
        ctx.save();
  
        // Cache images to avoid reloading and flickering
        const imageCache = {};
  
        // Count how many prescription events occur on the same date
        const dateCount = prescriptionEvents.reduce((acc, { timestamp }) => {
          const date = moment(timestamp).startOf('day').toString();
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});
  
        // Function to draw the prescription box
        function drawPrescriptionBox(prescriptionEvent, isHovered = false, yOffset = 0) {
          const { prescription, timestamp } = prescriptionEvent

          if (!imageCache[prescription.type]) {
            const image = new Image();
            image.src = ImageMap[prescription.type];
            imageCache[prescription.type] = image;
          }
  
          const image = imageCache[prescription.type];
          const imageWidth = 30;
          const aspectRatio = image.naturalWidth / image.naturalHeight;
          const imageHeight = imageWidth / aspectRatio;
          const paddingX = 5;
          const paddingY = 5;
          const textHeight = 30;
          const boxWidth = 100 + paddingX * 2;
          const boxHeight = imageHeight + textHeight + paddingY + 5;
          const borderRadius = 3;
  
          const xPosition = chart.scales.x.getPixelForValue(new Date(timestamp));
  
          // Calculate the y-position based on the number of prescriptions on the same date
          const baseYPosition = 15 + (yOffset * yPrescriptionSeparation); // Adjust based on separation
  
          const boxXPosition = xPosition - boxWidth / 2;
          const imageXPosition = boxXPosition + paddingX + (boxWidth - paddingX * 2 - imageWidth) / 2;
          const imageYPosition = baseYPosition + paddingY;
          const textXPosition = xPosition;
          const textYPosition = imageYPosition + imageHeight + paddingY + 5;
  
          // Draw rounded rectangle for the box background (with white color to cover line)
          function drawRoundedRect(ctx, x, y, width, height, radius) {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y);
            ctx.arcTo(x + width, y, x + width, y + radius, radius);
            ctx.lineTo(x + width, y + height - radius);
            ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
            ctx.lineTo(x + radius, y + height);
            ctx.arcTo(x, y + height, x, y + height - radius, radius);
            ctx.lineTo(x, y + radius);
            ctx.arcTo(x, y, x + radius, y, radius);
            ctx.closePath();
          }
  
          // Draw the white background for the box
          if (isHovered) {
            ctx.fillStyle = prescriptionEvent.type === HeartEventType.PRESCRIPTION_CREATED ? startColorBackground : stopColorBackground
          } else {
            ctx.fillStyle = 'white'
          }
          drawRoundedRect(ctx, boxXPosition, baseYPosition, boxWidth, boxHeight, borderRadius);
          ctx.fill();
  
          // Draw the grey border
          if (isHovered) {
            ctx.strokeStyle = prescriptionEvent.type === HeartEventType.PRESCRIPTION_CREATED ? startColor : stopColor
          } else {
            ctx.strokeStyle = '#DDDDDD'
          }
          ctx.lineWidth = 1;
          drawRoundedRect(ctx, boxXPosition, baseYPosition, boxWidth, boxHeight, borderRadius);
          ctx.stroke();
  
          // Draw the image in the box
          ctx.drawImage(image, imageXPosition, imageYPosition, imageWidth, imageHeight);
  
          // Draw the prescription type and date
          ctx.font = '11px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(`${prescription.type}`, textXPosition, textYPosition);
          ctx.font = '10px Arial';
          ctx.fillStyle = '#AAAAAA';
          ctx.fillText(`${moment(timestamp).format('MMM DD, YYYY')}`, textXPosition, textYPosition + 15);
        }
  
        const getSameDatePrescriptionEvents = (prescriptionEvent) => {
          return prescriptionEvents.filter(p => moment(p.timestamp).isSame(moment(prescriptionEvent.timestamp), 'day'));
        };
  
        // Step 1: Draw all non-hovered boxes first

        prescriptionEvents.forEach((prescriptionEvent, index) => {
          const sameDateIndex = getSameDatePrescriptionEvents(prescriptionEvent).findIndex(({ _id }) => _id === prescriptionEvent._id)
          const yOffset = sameDateIndex

          const isHovered = hoveredPrescriptionEvent?._id && getSameDatePrescriptionEvents(prescriptionEvent).some(({ _id }) => _id === hoveredPrescriptionEvent._id)
  
          // Only draw non-hovered boxes in this loop
          if (!isHovered) {
            drawPrescriptionBox(prescriptionEvent, false, yOffset);
          }
        })
  
        // Step 2: Draw the boxes for the hovered date last to bring them forward
        if (hoveredPrescriptionEvent) {
          const sameDatePrescriptionEvents = getSameDatePrescriptionEvents(hoveredPrescriptionEvent);

          sameDatePrescriptionEvents.forEach((prescriptionEvent, index) => {
            const yOffset = index % dateCount[moment(prescriptionEvent.timestamp).startOf('day').toString()];
            drawPrescriptionBox(prescriptionEvent, true, yOffset); // Draw hovered prescriptions
          });
        }
  
        ctx.restore();
      }
    };
  
    // Register the plugin for this specific chart
    ChartJS.register(prescriptionPlugin);
  
    // Deregister the plugin when the component unmounts
    return () => {
      ChartJS.unregister(prescriptionPlugin);
    };
  }, [prescriptionEvents, chartId, hoveredPrescriptionEvent]);
  
  // Update the hover event handler to highlight all prescriptions on the same date
  useEffect(() => {
    const canvas = document.getElementById(chartId);
  
    if (canvas) {
      const handleMouseMove = (event) => {
        // Count how many prescriptions occur on the same date
        const dateCount = prescriptionEvents?.reduce((acc, { timestamp }) => {
          const date = moment(timestamp).startOf('day').toString();
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        const chart = ChartJS.getChart(chartId);
        const rect = canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left; // Get mouse X relative to canvas
        const mouseY = event.clientY - rect.top;  // Get mouse Y relative to canvas
  
        const xPosition = chart.scales.x.getValueForPixel(event.offsetX);
        const hoveredLine = prescriptionEvents.find(({ timestamp }) => {
          const prescriptionTime = new Date(timestamp).getTime();
          return Math.abs(prescriptionTime - xPosition) < 1000 * 60 * 60 * 24 * 7; // within a week range
        });
  
        // Check if hovering over a prescription box
        const hoveredBox = prescriptionEvents.find((prescriptionEvent) => {
          const xPositionBox = chart.scales.x.getPixelForValue(new Date(prescriptionEvent.timestamp));
          const boxWidth = 110;
          const boxHeight = 70;
          const yPositionBox = 15 + yPrescriptionSeparation * prescriptionEvents.findIndex(({ _id }) => _id === prescriptionEvent._id) % dateCount[moment(prescriptionEvent.timestamp).startOf('day').toString()]; // Adjust y position based on offset
  
          return (
            mouseX >= xPositionBox - boxWidth / 2 &&
            mouseX <= xPositionBox + boxWidth / 2 &&
            mouseY >= yPositionBox &&
            mouseY <= yPositionBox + boxHeight
          );
        });
  
        // Set hover state for all prescriptions on the same date
        const hoveredDatePrescriptions = hoveredBox || hoveredLine
          ? prescriptionEvents.filter(p => moment(p.timestamp).isSame(hoveredBox?.timestamp || hoveredLine?.timestamp, 'day'))
          : null;
  
        setHoveredPrescriptionEvent(hoveredDatePrescriptions ? hoveredDatePrescriptions[0] : null);
      };
  
      // Add mousemove event listener to detect hovering
      canvas.addEventListener('mousemove', handleMouseMove);
  
      // Cleanup event listener on unmount
      return () => {
        canvas.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [prescriptionEvents, chartId, hoveredPrescriptionEvent]);
  
  // Set clip: false in chart options
  useEffect(() => {
    setOptions({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'time', // Use time scale
          time: {
            unit: 'month', // Adjust based on your time span (e.g., day, month, year)
          },
          ticks: {
            maxTicksLimit: 5, // Show a maximum of 5 date labels on the x-axis
          },
          min: startDate,
          max: endDate,
          title: {
            display: false,
          },
          grid: {
            drawOnChartArea: true,
            clip: false, // Disable clipping for x-axis gridlines
            borderDash: [5, 5],
            drawBorder: true,
            color: (context) => {
              const yValue = context.chart.scales.y;
              return yValue.max <= 100 ? '#e0e0e0' : 'rgba(0,0,0,0)';
            }
          },
        },
        y: {
          beginAtZero: true,
          max: 130,
          ticks: {
            stepSize: 10,
            callback: function(value) {
              if (value % 10 === 0 && value <= 100) {
                return value; // Only display these values
              }
              return ''; // Hide other values
            }
          },
          grid: {
            drawTicks: true,
            drawOnChartArea: true,
            color: (context) => {
              return context.tick.value <= 100 ? '#e0e0e0' : 'rgba(0,0,0,0)';
            }
          },
          border: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    });
  }, [startDate, endDate, prescriptionEvents]);

  // Function to convert the trend to Chart.js data format
  function convertToChartData(trend) {
    const heartData = [];
    const metabolismData = [];
    const liverData = [];
    const bpData = [];
    const labels = [];
  
    trend.forEach(item => {
      labels.push(item.collectedAt); // Collect dates
      if (isNumber(item.trends.heart)) {
        heartData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.heart,
        });
      }
      if (isNumber(item.trends.metabolism)) {
        metabolismData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.metabolism,
        });
      }
      if (isNumber(item.trends.liver)) {
        liverData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.liver,
        });
      }
      if (isNumber(item.trends.bp)) {
        bpData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.bp,
        });
      }
    });
  
    return [
      {
        code: 'heart',
        label: 'Heart',
        data: heartData,
        borderColor: heartColor,
        fill: false,
        tension: 0.2,
        spanGaps: true,
      },
      {
        code: 'metabolism',
        label: 'Metabolism',
        data: metabolismData,
        borderColor: metabolismColor,
        fill: false,
        tension: 0.2,
        spanGaps: true,
      },
      {
        code: 'liver',
        label: 'Liver',
        data: liverData,
        borderColor: liverColor,
        fill: false,
        tension: 0.2,
        spanGaps: true,
      },
      {
        code: 'bp',
        label: 'Blood Pressure',
        data: bpData,
        borderColor: bpColor,
        fill: false,
        tension: 0.2,
        spanGaps: true,
      },
    ]
  }  

  const getLatestValue = (system) => {
    const trendItem = trend.find(({ trends }) => isNumber(trends[system]))
    if (!trendItem) return <Text className="no-value">N/A</Text>
    return `${trendItem.trends[system]}%`
  }

  const RecentScores = () => {
    return (
      <div className="recent-scores">
        <div className="score-item" onClick={() => setShowHeart(!showHeart)}>
          <span className="score-bullet" style={{ backgroundColor: showHeart ? heartColor : '#bbb' }}/>
          <Text className="score-label">Heart:</Text> <Text className="score-value">{getLatestValue('heart')}</Text>
        </div>

        <div className="score-item" onClick={() => setShowMetabolism(!showMetabolism)}>
          <span className="score-bullet" style={{ backgroundColor: showMetabolism ? metabolismColor : '#bbb' }}/>
          <Text className="score-label">Metabolism:</Text> <Text className="score-value">{getLatestValue('metabolism')}</Text>
        </div>

        <div className="score-item" onClick={() => setShowLiver(!showLiver)}>
          <span className="score-bullet" style={{ backgroundColor: showLiver ? liverColor : '#bbb' }}/>
          <Text className="score-label">Liver:</Text> <Text className="score-value">{getLatestValue('liver')}</Text>
        </div>

        <div className="score-item" onClick={() => setShowBp(!showBp)}>
          <span className="score-bullet" style={{ backgroundColor: showBp ? bpColor : '#bbb' }}/>
          <Text className="score-label">BP:</Text> <Text className="score-value">{getLatestValue('bp')}</Text>
        </div>
      </div>
    )
  }

  return (
    <div className="heart-trend-line-expanded">
      <div className="chart-header">
        <div className="chart-btn-container">
          <Button  
            onClick={handlePrevious}
            className="chart-prev-btn"
            icon={<ArrowLeftOutlined />}
          />
          <Button 
            onClick={handleNext} 
            disabled={moment(endDate).isSameOrAfter(currentDate)}
            className="chart-next-btn"
            icon={<ArrowRightOutlined />}
          />
        </div>

        <Text className="chart-patient">
          {patient.firstName} {patient.lastName}
        </Text>
      </div>

      <Row className="heart-trend-row">
        <Col className="recent-scores-col">
          <RecentScores />
        </Col>
        <Col style={{ flex: 1, height: `100%` }}>
          <div 
            style={{ height: '100%' }}
            className="chart-container"
          >
            <Line 
              id={chartId}
              data={{
                datasets: chartData.filter(({ code }) => {
                  if (!showHeart && code === 'heart') return false
                  if (!showMetabolism && code === 'metabolism') return false
                  if (!showLiver && code === 'liver') return false
                  if (!showBp && code === 'bp') return false
                  return true
                })
              }} 
              options={options} 
            />
          </div>
        </Col>
      </Row>
    </div>
  )
};
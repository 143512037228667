import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import TestosteronePanelConfirm from "./testosteronePanelConfirm.component.js"
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import { updateProduct } from '../../services/product.service'

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  DELIVERY: 'delivery',
  // MEMBERSHIP: 'membership',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const TestosteronePanelFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership } = useContext(UserContext)
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [skeleton, setSkeleton] = useState()
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    if (!productTypes) return
    fetchSkeleton()
  }, [hasPass, membershipTypes, productTypes, instalabMembership, flow, initialUser])


  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    const productTypes = await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.TESTOSTERONE_PANEL_KIT
          ]
        }
      }
    })

    setProductTypes(productTypes)

  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return
    
    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.TESTOSTERONE_PANEL}`)}>Log in</a>.</>,         
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PHONE,
        title: `What's your delivery address?`,
        description: `We’ll send your test kit to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        // nextStep: Step.MEMBERSHIP,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      // [Step.MEMBERSHIP]: {
      //   type: StepType.PAY,
      //   nextStep: Step.PAY,
      //   addSubscription: true,
      //   subscriptionType: MembershipTypeCode.LONGEVITY,
      //   title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
      //   description: "This test is exclusive to members, who also enjoy special savings, a care concierge, access to longevity physicians, and more. Easily cancel anytime.",
      //   skipIf: () => {
      //     return instalabMembership
      //   }
      // },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: true,
        productType: ProductTypeCode.TESTOSTERONE_PANEL_KIT,
        title:<>Pay <span className="true-price">${getProductType(ProductTypeCode.TESTOSTERONE_PANEL_KIT)?.cost}</span> for the {getProductType(ProductTypeCode.TESTOSTERONE_PANEL_KIT).title}.</>,
        description: `This includes the Tasso+ sample collection kit and results within 1 week.`,

      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Confirmed!`,
        description: `Your test kit will be shipped within 1 business day. Tracking info will be available shortly in your account.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <TestosteronePanelConfirm/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      }
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}
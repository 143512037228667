const FlowType = Object.freeze({
  // Memberships
  ULTIMATE_MEMBERSHIP: 'ultimate',
  MEMBER: "member",

  // Test flows
  GALLERI_TEST: "galleri-test",
  COMPREHENSIVE_TEST: "comprehensive-test",
  PRENUVO: "prenuvo",
  TRUAGE_COMPLETE: "truage-complete",
  TRUAGE_PACE: "truage-pace",
  TOXIC_HAIR_TEST: 'toxic-hair-test',
  TOXIC_METALS_TEST: 'toxic-metals-test',
  CAC: 'coronary-calcium-scan',
  CHOLESTEROL_TREAMTENT_TEST: "cholesterol-treatment-test",
  OMEGAQUANT_COMPLETE: 'omega-3-index',
  LONGEVITY_TEST: 'longevity-test',
  LONGEVITY_TEST_PSC: 'longevity-test-psc',
  ATHLETE_TEST: 'athlete-test',
  DEXA: 'dexa',
  DEXA_BODYCOMP: 'dexa-bodycomp',
  VO2MAX: 'vo2max',
  FERTILITY: 'fertility',
  MOLD_TOXINS: "toxic-molds-test",
  SLEEP_APNEA: "sleep-apnea-test",
  BIOBEAT: 'biobeat',
  CLEERLY: 'cleerly',
  TESTOSTERONE: 'testosterone',
  TESTOSTERONE_PANEL: 'testosterone-panel',
  CGM: 'cgm',
  HEART_HEALTH_TEST: 'heart-health-test',
  CUSTOM_TEST: 'custom-test',
  LONGEVITY_GENETIC_TEST: 'longevity-genetic-test',
  DIURNAL_CORTISOL_PROFILE: 'diurnal-cortisol-profile',
  HEART_HEALTH_GENETIC_TEST: 'heart-health-genetic-test',

  // Treatments
  LEQVIO: "leqvio",
  CHOLESTEROL: "cholesterol",

  // Misc
  HEART_MEMBER_CONSULT: "heart-consult",
  LONGEVITY_CONSULT: "longevity-consult",
  HEART_HEALTH_INTAKE: 'heart-intake',
  HEART_HEALTH_UPGRADE: 'heart-upgrade',
  SUPPLEMENTS: "supplements",
  INTAKE: "intake", 
  GIFT: "gift",
  QUIZ: 'quiz',
  UPGRADE: 'upgrade',
  INVITE: 'invite',
  REFERRAL: 'referral',

  // Pro flows
  PRO_SIGNUP: "pro-signup",
  PRO_CONSULT: "pro-consult",
  PRO_DRAW: 'pro-draw',

  // DEMO - FOR TESTING ONLY
  DEMO: 'demo'
})

export default FlowType
import { Typography, Row, Col }  from 'antd'
import Breakpoint from "../../enums/breakpoint.enum";
import useWidth from '../../hooks/useWidth.hook';

const { Text, Paragraph } = Typography

export const SleepApneaTestIntro = ({ footer, productType }) => {
  const width = useWidth()

  const instructions = [{
    sections: [
      {
      title: <>Receive the WatchPat One Device</>,
      description: <>The WatchPat One is an FDA-approved sleep device with a wrist sensor, pulse oximeter, and chest sensor. It collects data from the wrist, finger, and neck.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Wear WatchPat One for one night',
      description: <>The device is super easy and comfortable to wear. It'll gather data on seven key sleep apnea metrics, which will be sent to Instalab for analysis.</>
    }]
  }, 
  {
    sections: [{
      title: 'Get Test Results',
      description: <>An Instalab physician will review your sleep data and provide a detailed personal sleep report with recommendations.</>
    }]
  }]
  
  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >


      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
          {productType.title} - ${productType.cost}
        </Paragraph>
        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
          Measure how well you sleep and whether you have sleep apnea using the FDA-approved <a href="https://www.itamar-medical.com/professionals/disposable-hsawatchpat-one/" target="_blank">WatchPat One device</a>.

        </Paragraph>

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row gutter={10}>
                <Col
                  xs={{ span: 5 }}
                  sm={{ span: 4 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                  xxl={{ span: 3 }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  xs={{ span: 19 }}
                  sm={{ span: 20 }}
                  md={{ span: 21 }}
                  lg={{ span: 21 }}
                  xl={{ span: 21 }}
                  xxl={{ span: 21 }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>

        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}


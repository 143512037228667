import React, { useEffect, useState } from 'react';
import { Popover, Row, Col, Button, message, Tooltip } from 'antd'
import './memberships.scss';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import MembershipStatus from '../../enums/membershipStatus.enum';
import { cancelMembership, resumeMembership } from '../../services/membership.service';
import moment from 'moment'
import FlowType from '../../enums/flowType.enum';
import { useNavigate } from 'react-router-dom';
import { Step as MemberStep } from '../memberFlow/memberFlow.component'
import MembershipHelper from '../../helpers/membership.helper';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { useRef } from 'react';
import { getDiscountByCode } from '../../services/discount.service';

const select = 'status startAt endAt'
const populate = [{
  path: 'membershipType',
  select: 'code'
}]

const features = [

  {
    title: `Your own longevity phyisican`,
    description: `Get expert, personalized guidance from longevity physicians via convenient online consultations.  Each 30 minute visit is just $99.`
  }, 
  {
    title: `Referrals to any additional specialized care`,
    description: `For any additional specialized care like colonoscopies or skin cancer checkups, we’ll connect you with top specialists to ensure a seamless healthcare experience.`
  },
  {
    title: `Personal care concierge`,
    description: `Your dedicated health assistant will handle appointment scheduling and coordinate directly with your physician team to ensure all your questions and needs are addressed.`
  },
  {
    title: `Exclusive access & discounts`,
    description: "Gain early access to any new, advanced diagnostics and special pricing on popular tests."
  }, 

]

const heartHealthFeatures = [
{
  key: 'treatment',
  title: "Personalized treatment plan by physician",
  description: "Your dedicated physician will review your results, discuss your heart health goals, and work with you to create a personalized treatment plan that fits your needs.",
},
{
  key: 'rx',
  title: "Medications prescribed & delivered",
  description: "Medications prescribed for heart health include metformin, statins, cholesterol absorption inhibitors, PCSK9 inhibitors, inclisiran and much more. We recommend which ones are best for you based on your medical history and preferences. All generics come with your heart health membership, and if you choose to take any brand medications, we'll help you find best pricing and get insurance coverage where possible."
},    
{
  key: 'testing',
  title: "Regular testing to track progress",
  description: "Membership includes frequent testing to ensure efficacy of any treatments and guide adjustments as needed."
}, 
{
  key: 'messaging',
  title: "Unlimited physician access",
  description: "Gain constant access to your physician for any questions, concerns, or advice through our unlimited messaging service, ensuring continuous support for your heart health journey.",
},
// {
//   key: 5,
//   title: "Exclusive events and content",
//   description: "Invitations to webinars, workshops, and seminars on heart health topics, plus access to a library of educational materials."
// },
]

export const Memberships = () => {
  const navigate = useNavigate()
  const { instalabMembership, setInstalabMembership, memberships, setMemberships, token } = useContext(UserContext)
  const [heartMembership, setHeartMembership] = useState()

  const [discount, setDiscount] = useState()

  const [resuming, setResuming] = useState([])
  const resumingRef = useRef()
  resumingRef.current = resuming

  const [canceling, setCanceling] = useState([])
  const cancelingRef = useRef()
  cancelingRef.current = canceling

  useEffect(() => {
    document.title = 'Membership | Instalab'
  }, [])

  useEffect(() => {
    fetchLipidMembership()
  }, [memberships])

  useEffect(() => {
    fetchHeartDiscount()
  }, [token])

  const fetchHeartDiscount = async () => {
    if (!token) return
    setDiscount(await getDiscountByCode(MembershipTypeCode.HEART_HEALTH))
  }

  const fetchLipidMembership = () => {
    if (!memberships) return
    setHeartMembership(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH) || MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LIPIDS))
  }

  const onMembershipUpdate = (membership) => {
    setMemberships(cachedMemberships => {
      return cachedMemberships.map(m => m._id === membership._id ? membership : m)
    })
    if (membership.membershipType.code === MembershipTypeCode.LONGEVITY) {
      setInstalabMembership(membership)
    }
  }

  const onCancelMembership = async (membership) => {
    setCanceling([
      ...cancelingRef.current,
      membership._id
    ])

    try {
      const updatedMembership = await cancelMembership(membership._id, {
        select,
        populate
      })
      message.info('Membership canceled')
      onMembershipUpdate(updatedMembership)
    } catch (err) {
      message.error('Failed to cancel membership')
    }
    setCanceling(cancelingRef.current.filter(membershipId => membershipId !== membership._id))
  }

  const onResumeMembership = async (membership) => {
    setResuming([
      ...resumingRef.current,
      membership._id
    ])
    try {
      const updatedMembership = await resumeMembership(membership._id, {
        select,
        populate
      })
      onMembershipUpdate(updatedMembership)
      message.success('Membership reactivated')
    } catch (err) {
      message.error('Failed to reactivate membership')
    }
    setResuming(resumingRef.current.filter(membershipId => membershipId !== membership._id))
  }

  const handleAdvancedClick = async () => {
    const url = (instalabMembership || discount) ? `/flow/${FlowType.HEART_HEALTH_UPGRADE}` : `/flow/${FlowType.HEART_HEALTH_INTAKE}`
    navigate(url)
  }

  return (
    <div className="memberships">
      <PageHeader
        title='Memberships'
      />


      <Row gutter={12} className="membership-plans-container">

      {MembershipHelper.isUltimate(instalabMembership) ? 
        <div className="membership-plans-container">You have the Ultimate Membership! Please email <a className="mail-link" href="mailto:concierge@instalab.com">concierge@instalab.com</a> with any assistance in managing your membership.</div>
      :
      <>
        {!heartMembership && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="membership-plan"
          >
            <div className="membership-plan-container">
              <div className="membership-plan-title">Core Plan</div>
                <div className="membership-plan-description">
                Comprehensive tools and personalized guidance to identify and manage potential health risks.

                </div>
                <div className="membership-plan-price">
                  $49 <span>per month</span>
                </div>
                <div className="membership-plan-cta">
                  {instalabMembership?.status === MembershipStatus.ACTIVE ? (
                    <Button
                      type="default"
                      className="cancel-btn"
                      danger
                      onClick={() => onCancelMembership(instalabMembership)}
                      loading={cancelingRef.current?.includes(instalabMembership._id)}
                    >
                      Cancel Membership
                    </Button>
                  ) : instalabMembership ? (
                    <Tooltip title={`Your membership will expire on ${moment(instalabMembership.endAt).format('MMM D, YYYY')}. Reactivate to continue using your benefits before it expires.`}>
                      <Button
                        type="primary"
                        className="join-btn"
                        onClick={() => onResumeMembership(instalabMembership)}
                        loading={resumingRef.current?.includes(instalabMembership._id)}
                      >
                        Reactivate Membership
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      type="primary"
                      className="join-btn"
                      onClick={() => navigate(`/flow/${FlowType.MEMBER}/${MemberStep.PAY}`)}
                    >
                      Join Now
                    </Button>
                  )}
                  
                </div>

                <div className="membership-features-container">
                <div className="feature-item">Includes access to all testing, plus:</div>

                  {features.map(({ title, description, key }) => (
                    <div key={key} className="feature-item">
                      <CheckOutlined className="checkmark" />
                      <div className="title">
                        {title}
                        <Popover content={description} title={title} trigger="click">
                          <InfoCircleOutlined className="info-icon" />
                        </Popover>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
          </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
          className="membership-plan"
        >
          <div className="membership-plan-container">
              <div className="membership-plan-title">Heart Health Plan</div>
              <div className="membership-plan-description">
                Join the many who have significantly lowered their heart disease risk with Instalab's physician-led program.
              </div>
              <div className="membership-plan-price">
                $149 <span>per month</span>
              </div>
              <div className="membership-plan-cta">
                {heartMembership?.status === MembershipStatus.ACTIVE ? (
                  <Button
                    type="default"
                    className="cancel-btn"
                    danger
                    onClick={() => onCancelMembership(heartMembership)}
                    loading={cancelingRef.current?.includes(heartMembership._id)}
                  >
                    Cancel Membership
                  </Button>
                ) : heartMembership ? (
                  <Tooltip title={`Your membership will expire on ${moment(heartMembership.endAt).format('MMM D, YYYY')}. Reactivate to continue using your benefits before it expires.`}>
                    <Button
                      type="primary"
                      className="join-btn"
                      onClick={() => onResumeMembership(heartMembership)}
                      loading={resumingRef.current?.includes(heartMembership._id)}
                    >
                      Reactivate Membership
                    </Button>
                  </Tooltip>
                ) : (
                <Button 
                  className="join-btn"
                  type="primary"
                  onClick={handleAdvancedClick}
                >
                  {instalabMembership ? 'Upgrade Now' : 'Join Now'}
                </Button>
                )}
              </div>
              
              <div className="membership-features-container">
                <div className="feature-item">Includes everything in "Core", plus:</div>
                {heartHealthFeatures.map(({ title, description, key }) => (
                  <div key={key} className="feature-item">
                    <CheckOutlined className="checkmark" />
                    <div className="title">
                      {title}
                      <Popover content={description} title={title} trigger="click">
                        <InfoCircleOutlined className="info-icon" />
                      </Popover>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </Col>
        </>}
      </Row>
      
    </div>
  )
}
import { Input, Form, Select } from "antd"
import "./multipleInput.scss"
import { WarningFilled } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useRef } from "react"
import FormHelper from "../../helpers/form.helper"
import RuleHelper from "../../../../helpers/rule.helper"
import { useNavigate, useParams } from "react-router-dom"

const { Item } = Form

export const MultipleInput = ({
  step,
  onSubmit,
  skeleton,
  form,
  isSubmitting,
  hasAttempt,
  flow,
}) => {
  const navigate = useNavigate()
  const [rules, setRules] = useState({})
  const inputRef = useRef(null)
  const { flowType } = useParams()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    fetchRules()
    inputRef.current.focus()
  }, [step])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const fetchRules = () => {
    if (!step) return
    let r = {}

    for (const field of step.fields) {
      let fieldRules = []

      if (field.required) {
        fieldRules.push(RuleHelper.isRequired)
      }

      if (field.date) {
        fieldRules.push(RuleHelper.isDate)
      }

      if (field.email) {
        fieldRules.push(RuleHelper.isEmail) 
      }

      if (field.email && field.unique) {
        fieldRules.push(RuleHelper.isUniqueEmail({
          fieldName: field.name,
          navigate, 
          redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
          userId: flow?.user?._id,
          uniqueError: field.uniqueError
        }))
      }

      if (field.phone) {
        fieldRules.push(RuleHelper.isPhone)
      }

      if (field.phone && field.unique) {
        fieldRules.push(RuleHelper.isUniquePhone({
          fieldName: field.name,
          navigate, 
          redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
          userId: flow?.user?._id,
          uniqueError: field.uniqueError
        }))
      }

      if (step.requiresOneField) {
        fieldRules.push({
          message: <><WarningFilled />&nbsp; Fill in at least one value</>,
          validator: (_, value) => {
            let fieldValues = form.getFieldsValue()
            const hasOneValue = Object.keys(fieldValues).some(fieldName => fieldValues[fieldName] && step.fields.some(({ name }) => name === fieldName))
            if (hasOneValue) {
              return Promise.resolve();
            } else {
              return Promise.reject('Fill in at least one field');
            }
          }
        })
      }

      r[field.name] = fieldRules
    }
    setRules(r)
  }
  const getFieldType = field => {
    if (field.password) {
      return 'password'
    } else if (field.number) {
      return 'number'
    }
    return 'default'
  }

  return (
    <div className="multiple-input">
      <Form
        form={form}
        className="multiple-input-form"
        layout="vertical"
      >
        {step.fields.map((field, fieldIndex) => (
          <div 
            key={`field-${field.name}`}
            className="field-container"
          >
            <Item 
              name={field.name}
              label={field.label}
              className="multiple-input-item"
              rules={rules[field.name]}
              validateTrigger='onSubmit'
            >
              <Input
                placeholder={field.placeholder}
                type={getFieldType(field)}
                className="multiple-input-field"
                inputMode={field.inputMode || 'text'}
                onChange={() => {
                  if (hasAttemptRef.current) {
                    FormHelper.fetchHasError(form)
                  }
                }}
                ref={fieldIndex === 0 ? inputRef : null}
              />
            </Item>

            {field.suffixField && (
              <Item 
                name={field.suffixField.name}
                className="suffix-item"
                validateTrigger='onSubmit'
              >
                <Select
                  className="multiple-select-field"
                  options={field.suffixField.options}
                  defaultValue={field.suffixField.default}
                />
              </Item>
            )}

            {field.suffix && (
              <div className="field-suffix">
                {field.suffix}
              </div>
            )}
          </div>
        ))}

        <FlowStepFooter 
          onSubmit={() => onSubmit()} 
          isSubmitting={isSubmitting}
        />
      </Form>
    </div>
  )
}
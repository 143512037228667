import { Typography, Button, Row, Col }  from 'antd'
import { useState, useEffect } from 'react'
import Breakpoint from '../../enums/breakpoint.enum.js'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import ProductTypeCode from "../../enums/productTypeCode.enum"
import useWidth from '../../hooks/useWidth.hook.js'


const { Text, Paragraph } = Typography

export const CacConfirm = ({ flow }) => {
  const [cardioInstructions, setCardioInstructions] = useState()
  const width = useWidth()

  useEffect(() => {
    fetchCardioInstructions()
  }, [flow])


  const instructions = [{
    sections: [
      {
      title: 'Schedule Your Scan',
      description: <>We'll contact you via email very soon to facilitate scheduling the scan at your preferred imaging center.</>
    }, 
    ]
  }, {
    sections: [{
      title: 'Get Scan Done',
      description: <>Avoid food, drink, caffeine and tobacco products for four hours before scan. The scan itself takes just a few minutes.</>
    }]
  }, {
    sections: [{
      title: 'Review Test Results',
      description: <>Most imaging centers will provide results within 48 hours. Upon receiving results, Instalab has specialists who can review your results with you.</>
    }]
  }]

  const fetchCardioInstructions = () => {
    if (!flow?.user) return;

    let cardioInstructions = []

    if (flow.user?.cardioTestPreference === ProductTypeCode.HEART_HEALTH_TEST_KIT) {
      cardioInstructions = [
      {
        sections: [
          {
          title: 'Receive Test Kit',
          description: <>It'll come within 2 business days. This includes everything you'll need to collect your blood and send to lab.</>
        }, 
        ]}, 
      {
        sections: [{
          title: 'Self Collect Blood + Mail Back',
          description: <>You'll use a Tasso device vs the usual fingerprick, which ensures a painless process. Just drop in mail when done.</>
        }]
      }, 
      {
        sections: [{
          title: 'Review Test Results',
          description: <>Test results will be ready within 1 week after your sample arrives at the lab. We'll email once posted to your account.</>
        }]
      }]
    }

    else if (flow.user?.cardioTestPreference === ProductTypeCode.HEART_HEALTH_LAB_VISIT) {
      cardioInstructions = [
      {
        sections: [
          {
          title: 'Receive Lab Order',
          description: <>We'll email you very shortly your lab order and closest Quest Diagnostic patient service center to get blood draw done.</>
        }, 
        ]}, 
        {
          sections: [{
            title: 'Get Blood Drawn',
            description: <>Visit any Quest Diagnostic patient service centers to get blood drawn (2,000+ locations). It's recommended to first schedule an appointment online to reduce waiting time.</>
          }]
        }, {
          sections: [{
            title: 'Get Test Results',
            description: <>Test results are sent within 1 week after your blood draw.</>
          }]
        }]
    }

    setCardioInstructions(cardioInstructions)

  }

  
  return flow?.user && (
    <div className="prescription-approval">

      <div style={{fontWeight:600, marginBottom:10}}>Next steps for coronary calcium scan:</div> 
      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      {(flow?.user?.cardioTestPreference === ProductTypeCode.HEART_HEALTH_TEST_KIT || flow?.user?.cardioTestPreference === ProductTypeCode.HEART_HEALTH_LAB_VISIT) && 
      <>
      <div style={{fontWeight:600, marginBottom:10}}>Next steps for advanced heart health test:</div> 
      {cardioInstructions?.length > 0 && (
        <div className="instruction-list">
          {cardioInstructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}
      </>
      }

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}


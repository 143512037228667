import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import FlowType from "../../enums/flowType.enum"
import { PanelModal } from "../panelModal/panelModal.component"
import { athletePanel } from "../../data/athletePanel.data"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { addSlack } from "../../services/slack.service"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { AllergyModal } from "../allergyModal/allergyModal.component"
import { getPhlebType } from "../../services/schedule.service"
import { completeFlow } from "../../services/flow.service"
import useWidth from "../../hooks/useWidth.hook"
import { updateProduct } from "../../services/product.service"

export const Step = {
  ACCOUNT: 'account',
  PAY: 'pay',
  LOCATION: 'location',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  REFUND: 'refund',
  SCHEDULE: 'schedule',
  UPSELL_ALLERGY: 'upsell',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation',
  INTAKE: 'intake',
  SHIPPING: 'shipping',
}

export const AthleteTestFlow = () => {
  const { instalabMembership } = useContext(UserContext)
  const width = useWidth()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [membershipType, setMembershipType] = useState()
  const [openAllergy, setOpenAllergy] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()
  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, flow, instalabMembership, hasPass, initialUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.ATHLETE_PANEL,
            ProductTypeCode.ALLERGY_PANEL,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const memberSavings = getProductType(ProductTypeCode.ATHLETE_PANEL)?.cost-getProductType(ProductTypeCode.ATHLETE_PANEL)?.memberCost-membershipType?.cost


  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({

      // [Step.UPSELL_ALLERGY]: {
      //   type: StepType.UPSELL,
      //   nextStep: Step.ACCOUNT,
      //   title: <>Add the Allergy Test for <span className="true-price">${getProductType(ProductTypeCode.ALLERGY_PANEL)?.cost}</span>.</>,
      //   points: [
      //     <>Included in the same blood draw appointment.</>,
      //     <>Measures antibodies to <a onClick={() => setOpenAllergy(true)}>19 of the most allergenic foods</a>.</>,
      //     <>Cleared by the U.S. Food and Drug Administration.</>,
      //   ],
      //   hasPurchased: flow?.products?.some(product => ProductTypeCode.ALLERGY_PANEL === product.productType?.code),
      //   buttonText: width > Breakpoint.SM ? 'Add Allergy Panel' : 'Add Panel',
      //   onUpsell: async () => {
      //     const response = await addProduct({
      //       fields: {
      //         type: ProductTypeCode.ALLERGY_PANEL,
      //         flowId: flow?._id
      //       },
      //     })
      //     setFlow(response.flow)
      //   },
      // },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.ATHLETE_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.LOCATION,
        addProduct: true,
        productType: ProductTypeCode.ATHLETE_PANEL,
        addLongevityMembership: true,
        title: (
          <>
            {(flow?.addLongevityMembership || instalabMembership) ? (
              <>
                Pay <span className="original-price">${getProductType(ProductTypeCode.ATHLETE_PANEL)?.cost}</span> <span className="true-price">${getProductType(ProductTypeCode.ATHLETE_PANEL)?.memberCost}</span> for the {getProductType(ProductTypeCode.ATHLETE_PANEL)?.title} 
                {!instalabMembership && <> and <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership</>}.
              </>
            ) : (
              <>Pay <span className="true-price">${getProductType(ProductTypeCode.ATHLETE_PANEL)?.cost}</span> for the {getProductType(ProductTypeCode.ATHLETE_PANEL)?.title}.</>
            )}
          </>
        ),
        description: <>This includes an at-home blood draw, results for <a className="secondary-link" onClick={() => setOpenPanel(true)}>70+ biomarkers</a>, and a personalized report with actionable steps to optimize your long-term health.</>,  
        membershipCTA: memberSavings > 10 ? `Save $${memberSavings} today with Instalab Membership` : 'Join Instalab Membership today',

      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.GENDER,
        // onNextStep: async (patient) => {
        //   const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
        //   return phlebType ? Step.GENDER : Step.REFUND
        // },
        title: `Where are you located?`,
        description: `Your phlebotomist will meet you here to draw your blood.`,
        field: {
          name: 'location'
        },
        onSuccess: async (patient) => {
          const location = flow?.appointment?.location || patient?.location
          if (!location) return
          const response = await getPhlebType({ location, flowId: flow?._id })
          if (response.phlebType) return

          await addSlack({
            channel: process.env.REACT_APP_PATIENTS_SLACK_WEBHOOK,
            message: `Refund ${FlowType.ATHLETE_TEST}: ${patient.email}`
          })
        }
      },
      // [Step.REFUND]: {
      //   type: StepType.STATIC,
      //   showFooter: false,
      //   title: `Unfortunately, we're not in your area yet...`,
      //   description: `Your full payment will be refunded in the next few days.`,
      //   enterStyle: { display: 'none' },
      //   buttonStyle: { display: 'none' },
      //   content: <ConfirmRefund />
      // },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `What is your date of birth?`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SCHEDULE,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.SCHEDULE,
        title: `Where should we ship your blood test kit?`,
        description: `You will receive a test kit in the mail containing all the supplies needed for our phlebotomist to complete your blood draw appointment.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Schedule your blood draw appointment.`,
        description: `You must fast 8 hours before your appointment. Water and black coffee are fine. The appointment takes 15 min tops.`,
      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: `Your blood draw appointment is confirmed!`,
        description: `Now we just need a little more information to conduct your blood draw appointment and interpret your test results correctly.`,
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    <AllergyModal
      open={openAllergy}
      setOpen={setOpenAllergy}
    />

    <PanelModal
      panel={athletePanel}
      title="The Athlete Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  </>
}
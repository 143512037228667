import { message, Spin } from "antd";
import { useState, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { createPaymentIntent } from '../../../../services/stripe.service';
import { addKlarnaProduct } from "../../../../services/product.service";
import { getProductTypeByCode } from "../../../../services/productType.service";
import "./klarnaPay.scss"

// Initialize Stripe outside the component to ensure it is loaded once
loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const KlarnaPayComponent = ({ clientSecret, isProcessingPayment, setIsProcessingPayment, payer }) => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!clientSecret || isProcessingPayment || !stripe) return;

    const handleKlarnaSubmit = async () => {
      setLoading(true);
      setIsProcessingPayment(true);

      try {
        const { location, shippingLocation } = payer;
        const address = {
          line1: location?.street1 || shippingLocation?.street1,
          city: location?.city || shippingLocation?.city,
          country: "US",
          postal_code: location?.postalCode || shippingLocation?.postalCode,
        };

        const result = await stripe.confirmKlarnaPayment(clientSecret, {
          payment_method: {
            billing_details: {
              email: payer.email,
              name: `${payer.firstName} ${payer.lastName}`,
              address,
            },
          },
          return_url: window.location.href,
        });

        result.error ? message.error(result.error.message) : message.success("Redirecting to Klarna...");
      } catch (error) {
        message.error("Failed to process Klarna payment.");
        console.error("Klarna payment error:", error);
      } finally {
        setLoading(false);
      }
    };

    handleKlarnaSubmit();
  }, [clientSecret, isProcessingPayment, stripe, payer, setIsProcessingPayment]);

  return (
    <div className="klarna-pay">
      {loading && (
        <div className="spinner-container">
          <Spin size="large" />
          <div style={{ marginTop: 10, textAlign: "center" }}>One Moment Please<br/>Loading Klarna</div>
        </div>
      )}
    </div>
  );
};

export const KlarnaPay = ({ step, flow, onNextStep, onPrevStep }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const location = useLocation();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    const fetchClientSecret = async () => {
      const productType = await getProductTypeByCode(step.productType);
      const cost = Number(productType.cost);

      try {
        const response = await createPaymentIntent({
          amount: cost * 100,
          currency: "usd",
          payment_method_types: ["klarna"],
        });
        setClientSecret(response.clientSecret);
      } catch (error) {
        message.error("Failed to initialize Klarna payment.");
      }
    };

    fetchClientSecret();
  }, [step]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const paymentIntentId = query.get("payment_intent");
    const redirectStatus = query.get("redirect_status");

    if (paymentIntentId && redirectStatus === "succeeded" && !isProcessingPayment) {
      setIsProcessingPayment(true);

      stripe.confirmKlarnaPayment(query.get("payment_intent_client_secret"))
        .then(result => {
          if (result.error) {
            message.error("Klarna payment could not be confirmed.");
          } else {
            addKlarnaProduct({
              fields: { paymentIntent: result.paymentIntent, type: step.productType, flowId: flow._id }
            }).then(() => message.success("Klarna payment confirmed!"));
          }
        })
        .catch(() => message.error("An unexpected error occurred."))
        .finally(() => {
          onNextStep();
          setIsProcessingPayment(false);
        });
    }

    else if (redirectStatus === "failed") {
      onPrevStep();
    }
  }, [location, isProcessingPayment, stripe, step, flow, onNextStep]);

  return (
    <>
      {clientSecret ? (
        <KlarnaPayComponent
          clientSecret={clientSecret}
          payer={flow.user}
          isProcessingPayment={isProcessingPayment}
          setIsProcessingPayment={setIsProcessingPayment}
        />
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
          <div style={{ marginTop: 10, textAlign: "center" }}>One Moment Please<br/>Loading Klarna</div>
        </div>
      )}
    </>
  );
};
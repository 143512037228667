import { Line } from 'react-chartjs-2';
import { useState, useEffect } from 'react'
import { Typography, Modal } from 'antd'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns'; // Import date-fns adapter for time scale
import './heartTrendLine.scss';
import { HeartTrendLineExpanded } from '../heartTrendLineExpanded/heartTrendLineExpanded.component';

// Register the required components for Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, TimeScale, annotationPlugin);

const heartColor = '#D9368B'
const metabolismColor = '#4A90E2'
const liverColor = '#9B59B6'
const bpColor = '#00BCD4'

const { Text } = Typography

const isNumber = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const HeartTrendLine = ({ 
  chartId, 
  trend, 
  prescriptionEvents,
  patient,
}) => {
  const [openModal, setOpenModal] = useState()

  // Convert the trend data to Chart.js format
  const chartData = convertToChartData(trend);

  // Function to convert the trend to Chart.js data format
  function convertToChartData(trend) {
    const heartData = [];
    const metabolismData = [];
    const liverData = [];
    const bpData = [];
    const labels = [];
  
    trend.forEach(item => {
      labels.push(item.collectedAt); // Collect dates
      if (isNumber(item.trends.heart)) {
        heartData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.heart,
        });
      }
      if (isNumber(item.trends.metabolism)) {
        metabolismData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.metabolism,
        });
      }
      if (isNumber(item.trends.liver)) {
        liverData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.liver,
        });
      }
      if (isNumber(item.trends.bp)) {
        bpData.push({
          x: new Date(item.collectedAt), // Use dates for x-axis values
          y: item.trends.bp,
        });
      }
    });
  
    return [
      {
        code: 'heart',
        label: 'Heart',
        data: heartData,
        borderColor: heartColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'metabolism',
        label: 'Metabolism',
        data: metabolismData,
        borderColor: metabolismColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'liver',
        label: 'Liver',
        data: liverData,
        borderColor: liverColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
      {
        code: 'bp',
        label: 'Blood Pressure',
        data: bpData,
        borderColor: bpColor,
        fill: false,
        tension: 0.3,
        spanGaps: true,
      },
    ]
  }  

  // Chart.js configuration options with fewer dates shown on x-axis
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time', // Use time scale
        time: {
          unit: 'month', // Adjust based on your time span (e.g., day, month, year)
        },
        ticks: {
          maxTicksLimit: 5, // Show a maximum of 5 date labels on the x-axis
        },
        title: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Disable the legend if not needed
      },
      title: {
        display: false, // Disable the title
      },
    },
  };

  const getLatestValue = (system) => {
    const trendItem = trend.find(({ trends }) => isNumber(trends[system]))
    if (!trendItem) return <Text className="no-value">N/A</Text>
    return `${trendItem.trends[system]}%`
  }

  const RecentScores = () => {
    return (
      <div className="recent-scores">
        <div className="score-item">
          <span className="score-bullet" style={{ backgroundColor: heartColor }}/>
          <Text className="score-label">Heart:</Text> <Text className="score-value">{getLatestValue('heart')}</Text>
        </div>

        <div className="score-item">
          <span className="score-bullet" style={{ backgroundColor: metabolismColor }}/>
          <Text className="score-label">Metabolism:</Text> <Text className="score-value">{getLatestValue('metabolism')}</Text>
        </div>

        <div className="score-item">
          <span className="score-bullet" style={{ backgroundColor: liverColor }}/>
          <Text className="score-label">Liver:</Text> <Text className="score-value">{getLatestValue('liver')}</Text>
        </div>

        <div className="score-item">
          <span className="score-bullet" style={{ backgroundColor: bpColor }}/>
          <Text className="score-label">BP:</Text> <Text className="score-value">{getLatestValue('bp')}</Text>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Modal 
        open={openModal}
        width={'90vw'}
        onCancel={() => setOpenModal(false)}
        style={{ top: 20, height: '90vh', width: '90vw' }}
        bodyStyle={{ height: '90vh' }}
        footer={null}
        className="expanded-modal"
      >
        <HeartTrendLineExpanded
          chartId={chartId}
          trend={trend}
          prescriptionEvents={prescriptionEvents}
          heartColor={heartColor}
          metabolismColor={metabolismColor}
          liverColor={liverColor}
          bpColor={bpColor}
          patient={patient}
        />
      </Modal>

      <div 
        className="heart-trend-line"
        onClick={() => {
          setOpenModal(true)
        }}
      >
        <RecentScores />

        <div className="chart-container">
          <Line 
            data={{
              datasets: chartData
            }} 
            options={options} 
          />
        </div>
      </div>
    </div>
  )
};
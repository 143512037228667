import React from "react";
import "./vatAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const VatAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [vatTests, setVatTests] = useState([]);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, vatTests, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setVatTests(ReportHelper.getSectionTests(report, ReportSection.VAT_ADVICE, tests) || []);
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);

    if (!report || !results || !tests || !vatTests) return null;
  
    let content = (
      <>
       Your <span>VAT mass</span> is <span>high</span>, which increases your risk for insulin resistance and other metabolic conditions.
      </>
    );

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !vatTests) return null;

    return (
      <>
      Visceral adipose tissue (VAT) is the fat surrounding your internal organs, and unlike subcutaneous fat, it is closely linked to increased risks of type 2 diabetes, cardiovascular diseases, and other metabolic disorders.<br/><br/>A combination of a healthy diet, regular exercise, and stress management can help decrease VAT levels.
      </>
    );
  };

  

  return (
    vatTests && (
      <div className="vat-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={vatTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />

      </div>
    )
  );
};
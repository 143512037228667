import FlowType from "../enums/flowType.enum"
import { GalleriFlow } from "../components/galleriFlow/galleriFlow.component"
import { MemberFlow } from "../components/memberFlow/memberFlow.component"
import { PrescriptionFlow } from "../components/prescriptionFlow/prescriptionFlow.component"
import { LongevityConsultFlow } from "../components/longevityConsultFlow/longevityConsultFlow.component"
import { IntakeFlow } from "../components/intakeFlow/intakeFlow.component"
import { PrenuvoFlow } from "../components/prenuvoFlow/prenuvoFlow.component"
import { TruageCompleteFlow } from "../components/truageCompleteFlow/truageCompleteFlow.component"
import { ComprehensiveTestFlow } from "../components/comprehensiveTestFlow/comprehensiveTestFlow.component"
import { CacFlow } from "../components/cacFlow/cacFlow.component"
import { QuizFlow } from "../components/quizFlow/quizFlow.component"
import { TruagePaceFlow } from "../components/truagePaceFlow/truagePaceFlow.component"
import { HeartHealthTestFlow } from "../components/heartHealthTestFlow/heartHealthTestFlow.component"
import { SupplementFlow } from "../components/supplementFlow.js/supplementFlow.component"
import { LongevityTestFlow } from "../components/longevityTestFlow/longevityTestFlow.component"
import { UpgradeFlow } from "../components/upgradeFlow/upgradeFlow.component"
import { CholesterolTreatmentTestFlow } from "../components/cholesterolTreatmentTestFlow/cholesterolTreatmentTestFlow.component"
import { InviteFlow } from "../components/inviteFlow/inviteFlow.component"
import { ToxicMetalsTestFlow } from "../components/toxicMetalsTestFlow/toxicMetalsTestFlow.component"
import { FertilityTestFlow } from "../components/fertilityTestFlow/fertilityTestFlow.component"
import { AthleteTestFlow } from "../components/athleteTestFlow/athleteTestFlow.component"
import { DexaFlow } from "../components/dexaFlow/dexaFlow.component"
import { ToxicMoldsTestFlow } from "../components/toxicMoldsTestFlow/toxicMoldsTestFlow.component"
import { ProConsultFlow } from "../components/proConsultFlow/proConsultFlow.component"
import { SleepApneaTestFlow } from "../components/sleepApneaTestFlow/sleepApneaTestFlow.component"
import { ProSignupFlow } from "../components/proSignupFlow/proSignupFlow.component"
import { BiobeatFlow } from "../components/biobeatFlow/biobeatFlow.component"
import { ProDrawFlow } from "../components/proDrawFlow/proDrawFlow.component"
import { CleerlyFlow } from "../components/cleerlyFlow/cleerlyFlow.component"
import { TotalTestosteroneTestFlow } from "../components/totalTestosteroneTestFlow/totalTestosteroneTestFlow.component"
import { TestosteronePanelFlow } from "../components/testosteronePanelFlow/testosteronePanelFlow.component"
import { CgmFlow } from "../components/cgmFlow/cgmFlow.component"
import { HeartHealthIntakeFlow } from "../components/heartHealthIntake/heartHealthIntake.component"
import { UltimateMemberFlow } from "../components/ultimateMemberFlow/ultimateMemberFlow.component"
import { LeqvioFlow } from "../components/leqvioFlow/leqvioFlow.component"
import { DexaBodyCompFlow } from "../components/dexaBodyCompFlow/dexaBodyCompFlow.component"
import { HeartHealthUpgradeFlow } from "../components/heartHealthUpgrade/heartHealthUpgrade.component"
import { HeartMemberConsultFlow } from "../components/heartMemberConsultFlow/heartMemberConsultFlow.component"
import { LongevityGeneticTestFlow } from "../components/longevityGeneticTestFlow/longevityGeneticTestFlow.component"
import { Vo2maxFlow } from "../components/vo2maxFlow/vo2maxFlow.component"
import { DiurnalCortisolProfileFlow } from "../components/diurnalCortisolProfileFlow/diurnalCortisolProfileFlow.component"
import { LongevityTestPscFlow } from "../components/longevityTestPscFlow/longevityTestPscFlow.component"
import { GiftFlow} from "../components/giftFlow/giftFlow.component"
import { HeartHealthGeneticTestFlow } from "../components/heartHealthGeneticTestFlow/heartHealthGeneticTestFlow.component"
import { DemoFlow } from "../components/demoFlow/demoFlow.component"
import { ReferralFlow } from "../components/referralFlow/referralFlow.component"

const FlowHelper = {
  getFlow: flowType => {
    switch (flowType) {
      case FlowType.INVITE:
        return {
          component: <InviteFlow />,
          forceNew: false
        }
      case FlowType.LONGEVITY_TEST:
      case FlowType.LONGEVITY_TEST_PSC:
        return {
          component: <LongevityTestFlow />,
          forceNew: true
        }
      case FlowType.MEMBER:
        return {
          component: <MemberFlow/>,
          forceNew: true,
        }
      case FlowType.HEART_HEALTH_INTAKE:
        return {
          component: <HeartHealthIntakeFlow />,
          forceNew: true
        }
      case FlowType.PRO_SIGNUP:
        return {
          component: <ProSignupFlow />,
          forceNew: false
        }
      case FlowType.HEART_HEALTH_UPGRADE:
        return {
          component: <HeartHealthUpgradeFlow />,
          forceNew: false
        }
      case FlowType.PRO_DRAW:
        return {
          component: <ProDrawFlow />,
          forceNew: true
        }
      case FlowType.LONGEVITY_TEST_PSC:
        return {
          component: <LongevityTestPscFlow/>,
          forceNew: true
        }
      case FlowType.ATHLETE_TEST:
        return {
          component: <AthleteTestFlow />,
          forceNew: true
        }
      case FlowType.COMPREHENSIVE_TEST:
        return {
          component: <ComprehensiveTestFlow/>,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_TEST:
        return {
          component: <HeartHealthTestFlow />,
          forceNew: true,
        }
      case FlowType.CHOLESTEROL:
        return {
          component: <PrescriptionFlow/>,
          forceNew: true,
        }
      case FlowType.GALLERI_TEST:
        return {
          component: <GalleriFlow/>,
          forceNew: true,
        }
      case FlowType.INTAKE:
        return {
          component: <IntakeFlow/>,
          forceNew: true,
        }
      case FlowType.PRENUVO:
        return {
          component: <PrenuvoFlow/>,
          forceNew: true
        }
      case FlowType.TRUAGE_COMPLETE:
        return {
          component: <TruageCompleteFlow />,
          forceNew: true
        }
      case FlowType.TRUAGE_PACE:
        return {
          component: <TruagePaceFlow />,
          forceNew: true
        }
      case FlowType.DIURNAL_CORTISOL_PROFILE:
        return {
          component: <DiurnalCortisolProfileFlow/>,
          forceNew: true
        }
      case FlowType.TOXIC_METALS_TEST:
        return {
          component: <ToxicMetalsTestFlow/>,
          forceNew: true
        }
      case FlowType.CAC:
        return {
          component: <CacFlow/>,
          forceNew: true,
        }
      case FlowType.CLEERLY:
        return {
          component: <CleerlyFlow/>,
          forceNew: true,
        }
      case FlowType.QUIZ:
        return {
          component: <QuizFlow/>,
          forceNew: true,
        }
      case FlowType.SUPPLEMENTS:
        return {
          component: <SupplementFlow/>,
          forceNew: true
        }
      case FlowType.UPGRADE:
        return {
          component: <UpgradeFlow />,
          forceNew: true,
        }
      case FlowType.CHOLESTEROL_TREAMTENT_TEST:
        return {
          component: <CholesterolTreatmentTestFlow/>,
          forceNew: true
        }
      case FlowType.DEXA:
        return {
          component: <DexaFlow/>,
          forceNew: true
        }
      case FlowType.DEXA_BODYCOMP:
        return {
          component: <DexaBodyCompFlow/>,
          forceNew: true
        }
      case FlowType.FERTILITY:
        return {
          component: <FertilityTestFlow/>,
          forceNew: true
        }
      case FlowType.MOLD_TOXINS:
        return {
          component: <ToxicMoldsTestFlow/>,
          forceNew: true
        }
      case FlowType.SLEEP_APNEA:
        return {
          component: <SleepApneaTestFlow/>,
          forceNew: true
        }
      case FlowType.BIOBEAT:
        return {
          component: <BiobeatFlow/>,
          forceNew: true
        }
      case FlowType.TESTOSTERONE:
        return {
          component : <TotalTestosteroneTestFlow/>,
          forceNew: true
        }
      case FlowType.TESTOSTERONE_PANEL:
        return {
          component: <TestosteronePanelFlow/>,
          forceNew: true
        }
      case FlowType.CGM:
        return {
          component: <CgmFlow/>,
          forceNew: true
        }
      case FlowType.ULTIMATE_MEMBERSHIP:
        return {
          component: <UltimateMemberFlow/>,
          forceNew: true,
        }
      case FlowType.LEQVIO:
        return {
          component: <LeqvioFlow/>,
          forceNew: true
        }
      case FlowType.LONGEVITY_GENETIC_TEST:
        return {
          component: <LongevityGeneticTestFlow/>,
          forceNew: true
        }
      case FlowType.VO2MAX:
        return {
          component: <Vo2maxFlow/>,
          forceNew: true
        }
      case FlowType.GIFT:
        return {
          component: <GiftFlow/>,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_GENETIC_TEST:
        return {
          component: <HeartHealthGeneticTestFlow/>,
          forceNew: true
        }
      case FlowType.REFERRAL:
        return {
          component: <ReferralFlow/>,
          forceNew: true
        }
      
      case FlowType.DEMO:
        return {
          component: <DemoFlow/>,
          forceNew: true
        }

      // Consults
      case FlowType.LONGEVITY_CONSULT:
        return {
          component: <LongevityConsultFlow />,
          forceNew: true
        }
      case FlowType.PRO_CONSULT:
        return {
          component: <ProConsultFlow />,
          forceNew: true
        }
      case FlowType.HEART_MEMBER_CONSULT:
        return {
          component: <HeartMemberConsultFlow />,
          forceNew: true
        }

      default:
        return null
  }
}
}

export default FlowHelper